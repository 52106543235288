import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const UserSearchFilter_Create = async (req) => {
  try {
    const response = await api.post(config.userSearchFilter_Create, {
      filter_name: req.filter_name,
      filter_definition: req.filter_definition,
      filter_type_id: req.filter_type_id,
      privacy: req.privacy,
      permission: req.permission,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const UserSearchFilter_Update = async (req) => {
  try {
    const response = await api.post(config.userSearchFilter_Update, {
      filter_id: req.filter_id,
      filter_name: req.filter_name,
      filter_definition: req.filter_definition,
      filter_type_id: req.filter_type_id,
      privacy: req.privacy,
      permission: req.permission,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const UserSearchFilter_Delete = async (req) => {
  try {
    const response = await api.post(config.userSearchFilter_Delete, {
      filter_id: req.filter_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const UserSearchFilter_Get = async (req) => {
  try {
    const response = await api.post(config.userSearchFilter_Get, {
      filter_type_id: req.filter_type_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export default {
  UserSearchFilter_Create,
  UserSearchFilter_Update,
  UserSearchFilter_Delete,
  UserSearchFilter_Get,
};
