import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getGovOverview_meetingInfo_Quickview_StockData,
  getBoardAndDirectorsIndexDDL,
  getComparisionTables,
  companyGovernanceOverviewDummyData,
  Get_Bylaws_Charter_GovGuidelines_Data_For_PDF
} from '../../../utils/company-util';
import { GOVERNANCE } from '../../../constants/ProductConstants';
import { HandleTrialLog, AllowDownload } from '../../../utils/general-util';

export const getGovOverview_meetingInfo_Quickview_StockDataReq =
  createAsyncThunk(
    'getGovOverview_meetingInfo_Quickview_StockData',
    async (res) => {
      const productsId = GOVERNANCE;
      const resAllowDownload = await AllowDownload(productsId); // Allow Download Option

      const response = await getGovOverview_meetingInfo_Quickview_StockData(res);

      const responseObj = {
        response,
        allowDownload: resAllowDownload,
      };
      return responseObj;
    }
  );

export const getComparisionTablesReq = createAsyncThunk(
  'getComparisionTables',
  async (res) => {
    const productsId = GOVERNANCE;
    const resAllowDownload = await AllowDownload(productsId); // Allow Download Option

    const response = await getComparisionTables(res);

    const responseObj = {
      response,
      allowDownload: resAllowDownload,
    };
    return responseObj;
  }
);

export const getBoardAndDirectorsIndexDDLReq = createAsyncThunk(
  'getBoardAndDirectorsIndexDDL',
  async (res) => {
    const response = await getBoardAndDirectorsIndexDDL(res);
    return response;
  }
);

export const getBylawsCharterGovGuidelinesDataForPDFReq = createAsyncThunk(
  'Get_Bylaws_Charter_GovGuidelines_Data_For_PDF',
  async (req) => {
    const response = await Get_Bylaws_Charter_GovGuidelines_Data_For_PDF(req);
    return response;
  }
);

const GovernanceOverviewSlice = createSlice({
  name: 'companyGovernanceOverview',
  initialState: {
    table_QuickView: {},
    table_MeetingInfo: {},
    table_BoardandDirectors: [],
    table_ShareholderRightsandVoting: [],
    table_Stock: [],
    table_Ownership: [],
    data_Chart: {},
    data_ChartInfo: '',
    data_corporateChartInfo: '',
    data_corporateChart: {},
    ddlComparision: [],
    selection_ddlComparision: [],

    trialStatus: false,
    allowDownload: true,
    isLoading: true,

    dataByLawForPDF: [],
    isPeerCol: false
  },
  reducers: {
    handleSetDDLComparision: {
      reducer(state, action) {
        state.selection_ddlComparision = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleIsLoading: {
      reducer(state, action) {
        state.isLoading = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleResetGovernance: {
      reducer(state) {
        state.table_QuickView = {};
        state.table_MeetingInfo = {};
        state.table_BoardandDirectors = [];
        state.table_ShareholderRightsandVoting = [];
        state.table_Stock = [];
        state.table_Ownership = [];
        state.data_Chart = {};
        state.data_ChartInfo = '';
        state.data_corporateChart = {};
        state.data_corporateChartInfo = '';
        state.ddlComparision = [];
        state.selection_ddlComparision = [];
      },
      prepare() {
        return {
          payload: null,
        };
      },
    },
  },
  extraReducers: {
    [getGovOverview_meetingInfo_Quickview_StockDataReq.fulfilled]: (
      state,
      action
    ) => {
      if (action.payload && action.payload.response) {
      state.table_QuickView =
        action.payload !== undefined
          ? action.payload.response.data_QuickView
          : [];
      state.table_MeetingInfo =
        action.payload !== undefined
          ? action.payload.response.data_MeetingInfo
          : [];
      state.table_Stock =
        action.payload !== undefined ? action.payload.response.data_Stock : [];
      state.data_Chart =
        action.payload !== undefined ? action.payload.response.data_Chart : [];
      state.data_ChartInfo =
        action.payload !== undefined
          ? action.payload.response.data_ChartInfo
          : [];
      state.data_corporateChartInfo =
        action.payload !== undefined
          ? action.payload.response.data_corporateChartInfo
          : [];
      state.data_corporateChart =
        action.payload !== undefined
          ? action.payload.response.data_corporateChart
          : [];
      state.trialStatus =
        action.payload !== undefined ? action.payload.trialStatus : false;
      state.allowDownload =
        action.payload !== undefined ? action.payload.allowDownload : true;
      }
      state.isLoading = action.payload === undefined;
    },
    [getComparisionTablesReq.fulfilled]: (state, action) => {
      if (action.payload && action.payload.response) {
        state.table_BoardandDirectors =
        action.payload !== undefined
        ? action.payload.response.dataBoardandDirectors
        : [];
        state.table_ShareholderRightsandVoting =
        action.payload !== undefined
        ? action.payload.response.dataShareholder
        : [];
        state.table_Ownership =
        action.payload !== undefined
        ? action.payload.response.dataOwnership
        : [];
        state.trialStatus =
        action.payload !== undefined ? action.payload.trialStatus : false;
        state.allowDownload =
        action.payload !== undefined ? action.payload.allowDownload : true;
        state.isLoading = action.payload === undefined;
        state.country_name = action.payload && action.payload.response.country_name;
        state.isPeerCol = action.payload.response.IsPeerCol;
      }
      state.isLoading = action.payload === undefined;
      },
      [getBoardAndDirectorsIndexDDLReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.ddlComparision =
          action.payload !== undefined ? action.payload.data : [];
        state.selection_ddlComparision =
          action.payload !== undefined ? action.payload.selectionData : [];
      }
    },
    [getBylawsCharterGovGuidelinesDataForPDFReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.dataByLawForPDF = action.payload;
        state.dataByLawForPDF.push(...state.table_Ownership);
      }
    },
  },
});

export const {
  handleResetLoading,
  handleSetDDLComparision,
  handleIsLoading,
  handleResetGovernance,
} = GovernanceOverviewSlice.actions;

export default GovernanceOverviewSlice.reducer;
