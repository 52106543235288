import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  GetESGScoreData
} from '../../../utils/toolsESGSCoreData-utils';

export const getESGScoreDataReq = createAsyncThunk('getESGScoreDataReq', async (req) => {
  const response = await GetESGScoreData(req);
  return response;
});

const EsgScoreDataSlice = createSlice({
  name: 'esgscoredataSlice',
  initialState: {
    isDataRelevance: false,
    isDataPercentile: false,
    showLengendInfo: false,
    lstEsgScoreData: [],
    isLoadedESGScore: true,
  },
  reducers: {
    handleResetData: {
      reducer(state, action) {
        state.isDataRelevance = action.payload;
      },
    },
    handleResetDataPerc: {
      reducer(state, action) {
        state.isDataPercentile = action.payload;
      },
    },
    HandleLengendInfoModel: {
      reducer(state, action) {
        state.showLengendInfo = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    HandleCloseLengendInfoModel: {
      reducer(state, action) {
        state.showLengendInfo = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    HandleResetESGScoreTool: {
      reducer(state, action) {
        state.lstEsgScoreData = [];
        state.isLoadedESGScore = true;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
  },
  extraReducers: {
    [getESGScoreDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.lstEsgScoreData =
          action.payload !== undefined ? action.payload : [];
      }
        state.isLoadedESGScore = false;
    },
  },
});

export const { handleResetData, handleResetDataPerc, HandleLengendInfoModel, HandleCloseLengendInfoModel, HandleResetESGScoreTool } = EsgScoreDataSlice.actions;

export default EsgScoreDataSlice.reducer;
