import {
  resolutionByInvestorTrackerFilter,
  investorTrackerResultDetails,
  historicalTrendsChartData,
  historicalTrendsChartYTDData,
  resolutionFilterByTotalVotesAnalysisYTD,
  resolutionFilterByTotalVotesAnalysis,
  historicalTrendsChartProxySeasonData,
  resolutionFilterByTotalProxySeasonVotesAnalysis,
  resolutionTrackerFilterByHistoricalTrends,
} from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { VOTING } from '../constants/ProductConstants';
import { dateToISOString } from './general-util';
import api from '../api';

export const ResolutionsByInvestorTrackerFilter = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(resolutionByInvestorTrackerFilter, {
      StartDate: dateToISOString(req.startDate),
      EndDate: dateToISOString(req.endDate),
      MeetingType: req.meetingType ? req.meetingType : '',
      Proponent: req.proponent ? req.proponent : '',
      ProposalSponsor: req.proposalSponsor ? req.proposalSponsor : null,
      CopampanySearchId: req.companySearchId ? req.companySearchId : null,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc ? req.managementRecc : null,
      outcome: req.outcome ? req.outcome : null,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const InvestorTrackerResultDetails = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(investorTrackerResultDetails, {
      StartDate: dateToISOString(req.startDate),
      EndDate: dateToISOString(req.endDate),
      MeetingType: req.meetingType,
      Proponent: req.proponent,
      ProposalSponsor: req.proposalSponsor,
      ProposalType: req.proposalType,
      ProposalTopLevel: req.proposalTypeTopLevel,
      ProposalSubLavel: req.ProposalTypeSubLevel,
      CopampanySearchId: req.companySearchId,
      Limited: req.limited,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
      fullData: req.fullData,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Chart
export const HistoricalTrendsChartData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(historicalTrendsChartData, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const HistoricalTrendsChartYTDData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(historicalTrendsChartYTDData, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const HistoricalTrendsChartProxySeasonData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(historicalTrendsChartProxySeasonData, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Table Analysis
export const ResolutionFilterByTotalProxySeasonVotesAnalysis = async (req) => {
  try {
    const response = await api.post(resolutionFilterByTotalProxySeasonVotesAnalysis, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const ResolutionFilterByTotalVotesAnalysisYTD = async (req) => {
  try {
    const response = await api.post(resolutionFilterByTotalVotesAnalysisYTD, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const ResolutionFilterByTotalVotesAnalysis = async (req) => {
  try {
    const response = await api.post(resolutionFilterByTotalVotesAnalysis, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.CopampanySearchId,
      management_recc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const ResolutionTrackerFilterByHistoricalTrends = async (req) => {
  try {
    const id = req.CopampanySearchId ? req.CopampanySearchId : req.companySearchId;
    const response = await api.post(resolutionTrackerFilterByHistoricalTrends, {
      ProposalType: req.ProposalType,
      ProposalTopLevel: req.ProposalTopLevel,
      ProposalSubLavel: req.ProposalSubLavel,
      MeetingType: req.MeetingType,
      Proponent: req.Proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: id,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
