import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { GetP4PAuth } from '../../../utils/p4pIFrameAuth-util';

export const getP4PAuthReq = createAsyncThunk('getCompensationComparatorDataReq', async (req) => {
    const response = await GetP4PAuth(req);
    return response;
  });

const P4PIframeSlice = createSlice({
  name: 'P4PIframeSlice',
  initialState: {
    p4pSecret: null
  },
  reducers: {
    handleResetSecret: {
      reducer(state, action) {
        state.p4pSecret = null;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
  },
  extraReducers: {
    [getP4PAuthReq.fulfilled]: (state, action) => {
        if (action.payload) {
          state.p4pSecret = action.payload ? action?.payload?.token : null;
        }
      },
  },
});

export const {
    handleResetSecret
} = P4PIframeSlice.actions;

export default P4PIframeSlice.reducer;
