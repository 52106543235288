import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../utils/axios-base-query';

// eslint-disable-next-line import/prefer-default-export
export const votingApi = createApi({
  reducerPath: 'votingApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v2' }),
  endpoints: (builder) => ({
    getVotingAgainstManagement: builder.query({
      query: () => ({ url: '/company/voting-against-management' }),
    }),
  }),
});

export const { useLazyGetVotingAgainstManagementQuery } = votingApi;
