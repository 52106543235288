import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetSettlementAgreementTableData } from '../../utils/tools-util';

export const getSettlementAgreementTableDataReq = createAsyncThunk('getSettlementAgreementTableDataReq', async (req) => {
  const response = await GetSettlementAgreementTableData(req);
  return response;
});

const settlementAgreementSlice = createSlice({
  name: 'settlementAggreement',
  initialState: {
    settlementAgreementData: [],
    isLoadingSettlementAgreement: true,
    allowDownload: true,
    trialStatus: false,
    userMessage: ''
  },
  reducers: {},
  extraReducers: {
    [getSettlementAgreementTableDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.isLoadingSettlementAgreement = false;
        state.settlementAgreementData = action.payload.data.length > 0 ? action.payload.data : [];
      } else {
        state.isLoadingSettlementAgreement = true;
        state.settlementAgreementData = [];
      }
    }
  }
});

export const { handleResetFillingDataSearch } = settlementAgreementSlice.actions;

export default settlementAgreementSlice.reducer;
