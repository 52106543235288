import api from '../api';
import {
  getActivismNewsAlerts,
  getActivismEventNewsAlerts,
  listFilingGroups,
  getAlertOptionsAndSubOptions,
  getDirectorType,
  govAmendemntCategories,
  updateTblalert,
  inserttblAlertOptionLink,
  inserttblAlertSubOptionLink,
  getExistingAlerts,
  getAlertDetails,
  deleteTblAlertOptionLink,
  getAlertModuleAccess,
  getInboxAlertName,
  getAlertFilingDetails,
  getSampleData,
  getAlertInboxType,
  getInboxAlertByUser,
  GetElementDetails,
  DeleteAlert,
  UpdateAlertStatus,
  GetAlertNotificationData,
  GetInboxAlertDetails,
  GetTop20AlertResult,
} from '../config/server-config';
import { TokenDecode } from './general-util';
import { API_CALL_SUCCESSFULL, NUMBER_TWO, NUMBER_FOUR } from '../constants/NumberConstants';

export const GetActivismNewsAlerts = async () => {
  try {
    const response = await api.post(getActivismNewsAlerts);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivismEventNewsAlerts = async () => {
  try {
    const response = await api.post(getActivismEventNewsAlerts);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListFilingGroups = async () => {
  try {
    const response = await api.post(listFilingGroups);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAlertOptionsAndSubOptions = async () => {
  try {
    const response = await api.post(getAlertOptionsAndSubOptions);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDirectorType = async () => {
  try {
    const response = await api.post(getDirectorType);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetGovAmendemntCategories = async () => {
  try {
    const response = await api.post(govAmendemntCategories);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateTblalert = async (res) => {
  try {
    const response = await api.post(updateTblalert, {
      alertid: res.AlertId,
      alertname: res.AlertName,
      companySearchId: res.CompanySearchId,
      investorSearchId: res.InvestorSearchId,
      receivedEmail: res.ReceivedEmail,
      receivedAlertInboxOnline: res.ReceivedAlertInboxOnline,
      notyfy_me_instant_alert: res.notyfy_me_instant_alert,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const InserttblAlertOptionLink = async (alertid, alertOptionId) => {
  try {
    const response = await api.post(inserttblAlertOptionLink, {
      alertid,
      alertOptionId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const InsertTblAlertSubOptionLink = async (optionLinkId, alertSubOptionId, value) => {
  try {
    const response = await api.post(inserttblAlertSubOptionLink, {
      optionLinkId,
      alertSubOptionId,
      value,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetExistingAlerts = async () => {
  try {
    const response = await api.post(getExistingAlerts);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAlertDetails = async (alertid) => {
  try {
    const response = await api.post(getAlertDetails, {
      alertid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const DeleteTblAlertOptionLink = async (alert_id) => {
  try {
    const response = await api.post(deleteTblAlertOptionLink, {
      alert_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const IsProductAvaibleForCurrentUser = async (productId) => {
  const resToken = await TokenDecode();
  if (resToken !== undefined && resToken !== null) {
    const productDetails = resToken.MemberShip.find((c) => c.product_id === productId);
    if (productDetails.status === NUMBER_FOUR || productDetails.status === NUMBER_TWO) {
      return true;
    }
    return false;
  }
};

export const GetAlertModuleAccess = async () => {
  try {
    const response = await api.post(getAlertModuleAccess);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//Alert inbox
export const GetAlertinboxName = async (element_type_id) => {
  try {
    const response = await api.post(getInboxAlertName, {
      element_type_id: element_type_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetFilingDetailsAlert = async () => {
  try {
    const response = await api.post(getAlertFilingDetails, {
      alert_inbox_id: 32,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetSampleData = async () => {
  try {
    const response = await api.post(getSampleData, {
      alert_inbox_id: 32,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//Alret inbox type
export const GetAlertInboxType = async (alert_id) => {
  try {
    const response = await api.post(getAlertInboxType, {
      alert_id: alert_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//Get Inbox Alert By User
export const GetInboxAlertByUser = async (alert_id, element_type_id, cancelToken) => {
  try {
    const response = await api.post(
      getInboxAlertByUser,
      {
        alert_id: alert_id,
        element_type_id: element_type_id,
      },
      {
        cancelToken: cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getElementDetails = async (element_type_id, alert_inbox_id, alert_data) => {
  try {
    const response = await api.post(GetElementDetails, {
      element_type_id: element_type_id,
      alert_inbox_id: alert_inbox_id,
      alert_data: alert_data,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const deleteAlert = async (alert_id) => {
  try {
    const response = await api.post(DeleteAlert, {
      alert_id: alert_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const updateAlertStatus = async (alert_inbox_id) => {
  try {
    const response = await api.post(UpdateAlertStatus, {
      alert_inbox_id: alert_inbox_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const getAlertNotificationData = async () => {
  try {
    const response = await api.post(GetAlertNotificationData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const getInboxAlertDetails = async (arg) => {
  try {
    const response = await api.post(GetInboxAlertDetails, {
      alert_option_id: arg.alert_option_id,
      alert_inbox_id: arg.alert_inbox_id,
      element_type_id: arg.element_type_id,
      alert_read: arg.alert_read,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const getTop20AlertResult = async () => {
  try {
    const response = await api.post(GetTop20AlertResult);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
