import dateFormat from 'dateformat';
import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { VOTING, COMPENSATION } from '../constants/ProductConstants';
import { dateToISOString } from './general-util';
import api from '../api';

export const GetCompensationComparatorData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      config.getCompensationComparatorData,
      {
        product_id: COMPENSATION,
        token,
        companySearchId: req.companySearchId,
        director_id: req.director_id,
        role: req.role,
        committee: req.committee,
        DateFrom: req.DateFrom !== null ? dateFormat(req.DateFrom, 'yyyy-mm-dd') : null, //new Date change
        DateTo: req.DateTo !== null ? dateFormat(req.DateTo, 'yyyy-mm-dd') : null, //new Date change
        CompensationType: req.compensationType,
        tenureFrom: req.tenureFrom,
        tenureTo: req.tenureTo
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetDirectorTypesData = async (req) => {
  try {
    const response = await api.post(
      config.GetDirectorTypes
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// getCompensationPolicySearchFilterData
export const GetCompensationPolicySearchFilterData = async (req) => {
  try {
    const response = await api.post(
      config.getCompensationPolicySearchFilterData
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationPolicySearchData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      config.getCompensationPolicySearchData,
      {
        product_id: COMPENSATION,
        token,
        company_search_id: req.company_search_id,
        policy_type: req.policy_type ? req.policy_type : null,
        policy_year: req.policy_year ? req.policy_year : null,
        search_text: req.search_text
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetSayOnPayData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      config.getSayOnPayData,
      {
        company_search_id: req.company_search_id,
        product_id: COMPENSATION,
        token
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetSayOnPayAnalytics = async (req) => {
  try {
    const response = await api.post(
      config.getSayOnPayAnalytics,
      {
        company_search_id: req.company_search_id
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
