import axios from 'axios';
import config from '../config/server-config';
import { TokenDecode } from './general-util';
import pathConst from '../constants/PathsConstant';
import numConst from '../constants/NumberConstants';
import api from '../api';

// PDF Download
export const getPDFOld = async (res) => {
  try {
    const tokenJSON = await TokenDecode();
    const response = await api.post(
      config.getPDFOld, // URL
      {
        pdfListItems: res.pdfListItems,
        pdfTitle: res.pdfTitle.replaceAll('System (', 'System-('),
        loginUrl: `${window.location.origin}${pathConst.CREDENTIAL_FORM}/`,
        token: localStorage.getItem('token'),
        User_Id: tokenJSON.User_Id !== undefined ? tokenJSON.User_Id : 0,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }, // Data
      {
        cancelToken: res.cancelToken, // For POST request, pass cancelToken as 3rd argument
        // responseType: 'arraybuffer',
        signal: res.signal,
      },
    );
    if (response.status === numConst.API_CALL_SUCCESSFULL) {
      if (response.data !== undefined && response.data.FilePath !== '') {
        // const blob = new Blob([response.data], {
        //   type: 'application/pdf;base64'
        // });
        // const fileURL = URL.createObjectURL(blob);
        res.setConfirmPDFTitle(res.pdfTitle);
        return {
          pdfstatus: true,
          pdffileURL: response.data.FilePath,
          error: response.data.errorMsg,
        };
      }
      return {
        pdfstatus: false,
        pdffileURL: response.data.FilePath,
        error: response.data.errorMsg,
      };
    }
    return { pdfstatus: false, pdffileURL: '', error: '' };
  } catch (err) {
    if (axios.isCancel(err)) {
      return { pdfstatus: false, pdffileURL: '', error: '' };
    }
    return { pdfstatus: false, pdffileURL: '', error: '' };
  }
};

/**
 * @param {string[]} documents
 * @param {string} pid
 * @param {string} origin
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getPDFLambda(documents, pid, origin, timeZone) {
  return api.post('/api/v2/pdf/getPDF', { documents, pid, origin, timeZone });
}

/**
 * @typedef {object} PdfStatus
 * @property {number} id
 * @property {string} pdfPath
 * @property {'idle'|'pending'|'success'|'error'} status
 */

/**
 * @param {number} pdfId
 * @returns {Promise<PdfStatus>}
 */
export async function getPdfStatus(pdfId) {
  const result = await api.post('/api/v2/pdf/getPDFStatus', { pdfId });
  return result.data;
}

export const getPDF = async (res) => {
  try {
    const tokenJSON = await TokenDecode();
    const response = await api.post(
      config.getPDF, // URL
      {
        pdfListItems: res.pdfListItems,
        pdfTitle: res.pdfTitle.replaceAll('System (', 'System-('),
        loginUrl: `${window.location.origin}${
          process.env.REACT_APP_IS_NATIVE_AWS === 'true' ? pathConst.SERVERSIDE_CALLBACK : pathConst.CREDENTIAL_FORM
        }/`,
        token: localStorage.getItem('token'),
        User_Id: tokenJSON.User_Id !== undefined ? tokenJSON.User_Id : 0,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }, // Data
      {
        cancelToken: res.cancelToken, // For POST request, pass cancelToken as 3rd argument
        // responseType: 'arraybuffer',
        signal: res.signal,
      },
    );
    if (response.status === numConst.API_CALL_SUCCESSFULL) {
      if (response.data !== undefined && response.data.FilePath !== '') {
        // const blob = new Blob([response.data], {
        //   type: 'application/pdf;base64'
        // });
        // const fileURL = URL.createObjectURL(blob);
        res.setConfirmPDFTitle(res.pdfTitle);
        return {
          pdfstatus: true,
          pdffileURL: response.data.FilePath,
          error: response.data.errorMsg,
        };
      }
      return {
        pdfstatus: false,
        pdffileURL: response.data.FilePath,
        error: response.data.errorMsg,
      };
    }
    return { pdfstatus: false, pdffileURL: '', error: '' };
  } catch (err) {
    // if (api.isCancel(err)) {
    //   return { pdfstatus: false, pdffileURL: '', error: '' };
    // }
    return { pdfstatus: false, pdffileURL: '', error: '' };
  }
};
export const getPDFClose = async () => {
  try {
    const response = await api.post(config.getPDFClose);
    if (response.status === numConst.API_CALL_SUCCESSFULL) {
      return {
        pdfstatus: false,
        pdffileURL: response.data.data,
        error: response.data.errorMsg,
      };
    }
  } catch (err) {
    if (api.isCancel(err)) {
      return { pdfstatus: false, pdffileURL: '', error: '' };
    }
  }
};

// Recent Downloads
export const getDataRecentDownloadList = async () => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.User_Id;
      })
      .catch((e) => new Error(e));

    const response = await api.post(config.getRecentDownloadList, {
      user_id: uid,
    });
    if (response.status === numConst.API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getPDFUrl = async (pdfName) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.User_Id;
      })
      .catch((e) => new Error(e));
    const response = await api.post(config.getPDFUrl, {
      user_id: uid,
      pdf_name: pdfName,
    });
    if (response.status === numConst.API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
