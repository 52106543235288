import {
  getActivistIdFromInvestor,
  listCampaignTypesbyActivist,
  getHoldingsbyCountryAiS,
  getHoldingsbyIndustryAiS,
  getHoldingsbyMarketCapAiS,
  getCampaignSummarybyActivistAiS,
  getInvestorIdFromActivist,
  getContactdetail,
  getInvAisTimeline,
  getPDFActivistShortCampaignsSummary
} from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const GetActivistIdFromInvestor = async (investorId) => {
  try {
    const response = await api.post(
      getActivistIdFromInvestor,
      {
        investorId
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvestorIdFromActivist = async (activist_id) => {
  try {
    const response = await api.post(
      getInvestorIdFromActivist,
      {
        activist_id
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListCampaignTypesbyActivist = async (activistId, longShort) => {
  try {
    const response = await api.post(
      listCampaignTypesbyActivist,
      {
        activistId: Number(activistId),
        longShort
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyCountryAiS = async (activistId) => {
  try {
    const response = await api.post(
      getHoldingsbyCountryAiS,
      {
        activistId: Number(activistId)
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyIndustryAiS = async (activistId) => {
  try {
    const response = await api.post(
      getHoldingsbyIndustryAiS,
      {
        activistId: Number(activistId)
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyMarketCapAiS = async (activistId) => {
  try {
    const response = await api.post(
      getHoldingsbyMarketCapAiS,
      {
        activistId: Number(activistId)
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCampaignSummarybyActivistAiS = async (activistId) => {
  try {
    const response = await api.post(
      getCampaignSummarybyActivistAiS,
      {
        activistId: Number(activistId)
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetContactdetail = async (arg) => {
  try {
    const response = await api.post(
      getContactdetail,
      {
        investor: Number(arg)
      },

    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvAisTimeline = async (arg) => {
  try {
    const response = await api.post(
      getInvAisTimeline,
      {
        investor: Number(arg)
      },

    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetPDFActivistShortCampaignsSummary = async (arg) => {
  try {
    const response = await api.post(
      getPDFActivistShortCampaignsSummary,
      {
        investor: Number(arg)
      },

    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
