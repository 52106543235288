import { sanitizeUrl } from '@braintree/sanitize-url';
import {
  getAdvisorProfile,
  getAdvisorModuleAccessData,
  getAdvisorActivismCompanyWebsite,
  getAdvisorActivismPersonnel,
  getAdvisorActivismCampaigns,
  getAdvisorActivistShortCampaigns,
  getIntermediaryData,
  getAdvisorVotingDetailInfo,
  getAdvisorVotingWindandInstrByYear,
  getLawFirmProposalTypes,
  getPagedAdvisorQucikSearchDataV2,
  getAdvisorPagedSearchDataV2,
  getAdvisorSearchDataV2,
} from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const GetAdvisorSearchData = async (nameSearch, quicksearch, limit, offset, cancelToken, isFullSearchResult) => {
  try {
    const response = await getRequestFunction();
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }

  function getRequestFunction() {
    const sanitizedName = sanitizeUrl(nameSearch);
    if (isFullSearchResult) {
      return api.get(getAdvisorSearchDataV2, { params: { nameSearch: sanitizedName }, cancelToken: cancelToken });
    }
    if (quicksearch) {
      return api.get(getPagedAdvisorQucikSearchDataV2, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    return api.get(getAdvisorPagedSearchDataV2, {
      params: { nameSearch: sanitizedName, limit, offset },
      cancelToken: cancelToken,
    });
  }
};

export const GetAdvisorProfile = async (companyId) => {
  try {
    const response = await api.post(getAdvisorProfile, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorModuleAccessData = async (companyId) => {
  try {
    const response = await api.post(getAdvisorModuleAccessData, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorActivismCompanyWebsite = async (companyId) => {
  try {
    const response = await api.post(getAdvisorActivismCompanyWebsite, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorActivismPersonnel = async (companyId) => {
  try {
    const response = await api.post(getAdvisorActivismPersonnel, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorActivismCampaigns = async (companyId) => {
  try {
    const response = await api.post(getAdvisorActivismCampaigns, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorActivistShortCampaigns = async (companyId) => {
  try {
    const response = await api.post(getAdvisorActivistShortCampaigns, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetIntermediaryData = async (companyId) => {
  try {
    const response = await api.post(getIntermediaryData, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorVotingDetailInfo = async (intermediaryId, companyid) => {
  try {
    const response = await api.post(getAdvisorVotingDetailInfo, {
      intermediaryId,
      companyid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdvisorVotingWindandInstrByYear = async (companyId) => {
  try {
    const response = await api.post(getAdvisorVotingWindandInstrByYear, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetLawFirmProposalTypes = async (companyId) => {
  try {
    const response = await api.post(getLawFirmProposalTypes, {
      companyId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
