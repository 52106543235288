import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { GetCGIECMAuth } from '../../../utils/CGIECMIFrameAuth-util';

export const getCGIECMAuthReq = createAsyncThunk('getCompensationComparatorDataReq', async (req) => {
    const response = await GetCGIECMAuth(req);
    return response;
  });

const CompensationCGIECMIframeSlice = createSlice({
  name: 'CompensationCGIECMIframeSlice',
  initialState: {
    cgiECMSecret: null
  },
  reducers: {
    handleResetSecret: {
      reducer(state, action) {
        state.cgiECMSecret = null;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
  },
  extraReducers: {
    [getCGIECMAuthReq.fulfilled]: (state, action) => {
        if (action.payload) {
          state.cgiECMSecret = action.payload ? action?.payload?.token : null;
        }
      },
  },
});

export const {
    handleResetSecret
} = CompensationCGIECMIframeSlice.actions;

export default CompensationCGIECMIframeSlice.reducer;
