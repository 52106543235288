import config from '../config/server-config';
import { dateToISOString, TokenDecode } from './general-util';
import {
  API_CALL_SUCCESSFULL,
  NUMBER_FOUR,
  NUMBER_TWO,
  NUMBER_MARKET_CAP_MAX,
  NUMBER_ZERO,
} from '../constants/NumberConstants';
import { deleteFromCache, readFromCache, writeToCache } from './cache-util';
import ProductConstants from '../constants/ProductConstants';
import api from '../api';

export const AddtblCompanySearchCountry = async (company_search_id, peerGroupIds, sectorIds) => {
  try {
    const response = await api.post(config.addtblCompanySearchCountry, {
      companySearchId: company_search_id,
      regionIds: peerGroupIds,
      countryIds: sectorIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const DeleteTblCompanySearchIndustry = async (company_search_id) => {
  try {
    const response = await api.post(config.deleteTblCompanySearchIndustry, {
      companySearchId: company_search_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const DeletetblCompanySearchCountry = async (company_search_id) => {
  try {
    const response = await api.post(config.deletetblCompanySearchCountry, {
      companySearchId: company_search_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblCompanySearchMarketCap = async (company_search_id, marketCap) => {
  try {
    const response = await api.post(config.addTblCompanySearchMarketCap, {
      companySearchId: company_search_id,
      marketCap,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblCompanySearchIndustry = async (company_search_id, sectorIds, peerGroupIds) => {
  try {
    const response = await api.post(config.addTblCompanySearchIndustry, {
      companySearchId: company_search_id,
      sectorIds,
      industryIds: peerGroupIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblCompanySearchPeerGroup = async (company_search_id, peerGroupIds) => {
  try {
    const response = await api.post(config.addTblCompanySearchPeerGroup, {
      companySearchId: company_search_id,
      peerGroupIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblcompanySearchExchange = async (company_search_id, exchangeIds) => {
  try {
    const response = await api.post(config.addTblcompanySearchExchange, {
      companySearchId: company_search_id,
      exchange_ids: exchangeIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetAllCompanySearchSelection = async (company_search_id) => {
  try {
    const response = await api.post(config.getCompanySearchSelection, {
      companySearchId: company_search_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblCopmanySearchCopmanies = async (company_search_id, pid) => {
  try {
    const response = await api.post(config.addTblCopmanySearchCopmanies, {
      companySearchId: company_search_id,
      pid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblcompanySearchIndex = async (company_search_id, indexIds) => {
  try {
    const response = await api.post(config.addTblcompanySearchIndex, {
      companySearchId: company_search_id,
      index_ids: indexIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetAllIssuers = async (text) => {
  try {
    const response = await api.post(config.getAllIssuers, { searchInput: text });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const PIListIndices = async () => {
  try {
    const cachedData = readFromCache(config.piListIndices);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.piListIndices);
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.piListIndices, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetAllExchange = async () => {
  try {
    const cachedData = readFromCache(config.getExchange);

    if (cachedData) {
      return cachedData;
    }

    const response = await api.post(config.getExchange, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getExchange, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetAIPeerGroups = async () => {
  try {
    const cachedData = readFromCache(config.getAIPeerGroups);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.getAIPeerGroups, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getAIPeerGroups, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetListRegionsAndCountries = async () => {
  try {
    const cachedData = readFromCache(config.getListRegionsAndCountries);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.getListRegionsAndCountries, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getListRegionsAndCountries, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const PIListSectorsAndIndustries = async () => {
  try {
    const cachedData = readFromCache(config.piListSectorsAndIndustries);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.piListSectorsAndIndustries, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.piListSectorsAndIndustries, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const FreeSearchCompanyAndIndustry = async (rowdata) => {
  try {
    const response = await api.post(config.freeSearchCompanyAndIndustry, {
      rowdata,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetCompanySearchOptions = async () => {
  try {
    const cachedData = readFromCache(config.getCompanySearchOptions);

    if (cachedData) {
      return cachedData;
    }

    const response = await api.post(config.getCompanySearchOptions);
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getCompanySearchOptions, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const UpdateCompanySearchOptions = async (
  name,
  company_search_id,
  action,
  marketCapMin,
  marketCpaMax,
  isSaveSelection,
  pid,
  dashboard_widget_link_id,
) => {
  try {
    const response = await api.post(config.updateCompanySearchOptions, {
      name,
      company_search_id,
      action,
      market_cap_min: Number(marketCapMin) && marketCapMin > NUMBER_ZERO ? marketCapMin : NUMBER_ZERO,
      market_cap_max:
        Number(marketCpaMax) !== 0
          ? Number(marketCpaMax) && marketCpaMax < NUMBER_MARKET_CAP_MAX
            ? marketCpaMax
            : NUMBER_MARKET_CAP_MAX
          : '',
      isSaveSelection,
      pid,
      dashboard_widget_link_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      deleteFromCache(config.getCompanySearchOptions);
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetUserRightsForWidgets = async () => {
  try {
    let membership;
    await TokenDecode().then((res) => {
      membership = res.MemberShip;
    });
    return membership;
  } catch (error) {
    return false;
  }
};

export const GetAllDashboardWidgets = async () => {
  try {
    const response = await api.post(config.getAllDashboardWidget);

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const UpdateTblDashboard = async (dashboardName, dashboardid) => {
  try {
    const response = await api.post(config.updateTblDashboard, {
      dashboardName,
      dashboardid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      if (dashboardid === null) {
        deleteFromCache(config.getUserDashboard);
        return response.data.recordset[0].Dashboard_id;
      }
      return dashboardid;
    }
    return undefined;
  } catch (error) {
    return false;
  }
};

export const AddtblDashboardWidgetLink = async (req) => {
  try {
    const response = await api.post(config.addtblDashboardWidgetLink, {
      dashboardId: req.dashboardId,
      widgetId: req.widgetId,
      widgetOrder: req.widgetOrder,
      companySearchId: req.companySearchId,
      investorSearchId: req.investorSearchId,
      renameWidget: req.renameWidget,
      position: req.position,
      dashboardWidgetLinkId: req.dashboardWidgetLinkId,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      deleteFromCache(config.getUserDashboard);
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetUserDashboard = async () => {
  try {
    const cachedData = false;

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.getUserDashboard);

    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getUserDashboard, response.data.recordset);
      return response.data.recordset;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const DeleteDashboardSelection = async (dashboardWidgetLinkId) => {
  try {
    const response = await api.post(config.deleteDashboardSelection, {
      dashboardWidgetLinkId,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      deleteFromCache(config.getUserDashboard);
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetCurrentShareholder = async () => {
  try {
    const response = await api.post(config.GetCurrentShareholder);
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetCurrentShareholder, response.data.data);
      return response.data.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const UpdateInvestorSearchOptions = async (
  name,
  investor_search_id,
  action,
  aum_min,
  aum_max,
  is_saved,
  dashboard_widget_link_id,
) => {
  try {
    const response = await api.post(config.UpdateInvestorSearchOptions, {
      name,
      investor_search_id,
      action,
      aum_min: Number(aum_min) && aum_min > NUMBER_ZERO ? aum_min : NUMBER_ZERO,
      aum_max:
        Number(aum_max) !== 0
          ? Number(aum_max) && aum_max < NUMBER_MARKET_CAP_MAX
            ? aum_max
            : NUMBER_MARKET_CAP_MAX
          : '',
      is_saved,
      dashboard_widget_link_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      deleteFromCache(config.GetInvestorsSearchOptions);
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblInvestorSearchInvestors = async (investor_search_id, investor_ids) => {
  try {
    const response = await api.post(config.AddTblInvestorSearchInvestors, {
      investor_search_id,
      investor_ids,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddTblInvestorSearchAUM = async (investor_search_id, AUM) => {
  try {
    const response = await api.post(config.AddTblInvestorSearchAUM, {
      investor_search_id,
      AUM,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const AddtblInvestors_SearchType = async (
  investor_search_id,
  investor_type_id,
  investor_subtype_id,
  location_selection,
) => {
  try {
    const response = await api.post(config.AddtblInvestors_SearchType, {
      investor_search_id,
      investor_type_id,
      investor_subtype_id,
      location_selection,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const DeletetblInvestors_SearchType = async (investor_search_id) => {
  try {
    const response = await api.post(config.DeletetblInvestors_SearchType, {
      investor_search_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const GetInvestorsSearchOptions = async () => {
  try {
    const cachedData = readFromCache(config.GetInvestorsSearchOptions);

    if (cachedData) {
      return cachedData;
    }

    const response = await api.post(config.GetInvestorsSearchOptions);

    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetInvestorsSearchOptions, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const Deletetblinvestor_search_country = async (investor_search_id) => {
  try {
    const response = await api.post(config.Deletetblinvestor_search_country, {
      investor_search_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const Addtblinvestor_search_country = async (investor_search_id, peerGroupIds, sectorIds) => {
  try {
    const response = await api.post(config.Addtblinvestor_search_country, {
      investor_search_id,
      regionIds: peerGroupIds,
      countryIds: sectorIds,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const ListInvestorTypeAndSubtype = async () => {
  try {
    const cachedData = readFromCache(config.ListInvestorTypeAndSubtype);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.ListInvestorTypeAndSubtype);
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.ListInvestorTypeAndSubtype, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const AddtblInvestors_byshareholdercompany = async (investor_search_id, pid) => {
  try {
    const response = await api.post(config.AddtblInvestors_byshareholdercompany, {
      investor_search_id,
      pid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const getInvestorSearchSelection = async (investor_search_id) => {
  try {
    const response = await api.post(config.GetInvestorSearchSelection, {
      investor_search_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const GetAllInvestors = async (text) => {
  try {
    const response = await api.post(config.GetAllInvestors, { searchInput: text });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const FreeSearchInvestor = async (rowdata) => {
  try {
    const response = await api.post(config.FreeSearchInvestor, {
      rowdata,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const GetAllInvestorsFromShareholderOfCompany = async (res) => {
  try {
    const response = await api.post(config.GetAllInvestorsFromShareholderOfCompany, { pid: res.value });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetAllDashboardWidgetsList = async () => {
  try {
    const response = await api.post(config.getTblDashboardWidgets);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetStoredProcedure = async (res) => {
  try {
    const response = await api.post(config.getStoredProcedure, {
      StoredProcedure: res.StoredProcedure,
      dashboard_widget_link_id: res.dashboard_widget_link_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetStoredProcedureDownload = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(config.getStoredProcedureDownload, {
      StoredProcedure: res.StoredProcedure,
      dashboard_widget_link_id: res.dashboard_widget_link_id,
      product_id: res.product_id,
      token,
      dashboard_widget_id: res.dashboard_widget_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const ResetDashboardWidgetCompanySearch = async (res) => {
  try {
    const response = await api.post(config.resetDashboardWidgetCompanySearch, {
      dashboard_widget_link_id: res.dashboard_widget_link_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const ResetDashboardWidgetInvestorSearch = async (res) => {
  try {
    const response = await api.post(config.resetDashboardWidgetInvestorSearch, {
      dashboard_widget_link_id: res.dashboard_widget_link_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetPortalsTop3News = async () => {
  try {
    const response = await api.post(config.GetPortalsTop3News);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDashboardIdData = async () => {
  try {
    const response = await api.post(config.GetDashboardIdData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const getHotActivistData = async () => {
  try {
    const response = await api.post(config.GetHotActivistData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getDirectorAppointmentChartData = async () => {
  try {
    const response = await api.post(config.GetDirectorAppointmentChartData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getAigRussell3000Score = async () => {
  try {
    const response = await api.post(config.GetAigRussell3000Score);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getShareHolderProposalESG = async (
  startDate,
  endDate,
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
) => {
  try {
    const response = await api.post(config.GetShareHolderProposalESG, {
      StartDate: dateToISOString(startDate),
      EndDate: dateToISOString(endDate),
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getVotingPolicyChangesESG = async () => {
  try {
    const response = await api.post(config.GetVotingPolicyChangesESG);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getUpCommingShareHolderESG = async () => {
  try {
    const response = await api.post(config.GetUpCommingShareHolderESG);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

function isBtnEnableFn(item, token) {
  if (item.is_aim === undefined || item.is_aim === null || !item.is_aim) {
    return true;
  }
  if (
    token.MemberShip.some((e) => e.product_id === ProductConstants.ACTIVIST_INSIGHT_MONTHLY && e.status === NUMBER_FOUR)
  ) {
    return true;
  }
  if (
    token.MemberShip.some((e) => e.product_id === ProductConstants.ACTIVIST_INSIGHT_MONTHLY && e.status === NUMBER_TWO)
  ) {
    if (item.is_aim && item.is_latest_aim) {
      return true;
    }
    if (item.is_aim) {
      return false;
    }
  }
  if (
    token.MemberShip.some(
      (e) =>
        e.product_id === ProductConstants.ACTIVIST_INSIGHT_MONTHLY &&
        (e.status !== NUMBER_FOUR || e.status !== NUMBER_TWO),
    )
  ) {
    return false;
  }
}

export const getMagazinesIssuesESG = async (res) => {
  try {
    const response = await api.post(config.GetMagazinesIssuesESG, {
      product_id: res.product_id,
      article_type_list: res.article_type_list,
    });
    const token = await TokenDecode();
    let TopMyJson = [];
    TopMyJson = await Promise.all(
      response?.data?.top3Mag.map((item) => ({
        ...item,
        enableBtn: isBtnEnableFn(item, token),
      })),
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return { data: { top3Mag: TopMyJson } };
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const GetErrorBoundryDetails = async (url, error, errorInfo) => {
  try {
    const response = await api.post(config.getErrorBoundryDetails, {
      url,
      error,
      errorInfo,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getVulnerabilityhitsData = async () => {
  try {
    const response = await api.post(config.GetVulnerabilityHitsData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const getAumCategorylist = async () => {
  try {
    const cachedData = readFromCache(config.getAumCategorylist);
    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.getAumCategorylist);
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.getAumCategorylist, response.data);
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const GetAllPeopleList = async (text) => {
  try {
    const response = await api.post(config.GetAllPeopleList, { text: text });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
