import { createContext } from 'react';

const DataContext = createContext({
    refreshDataButton: false,
    savedState: false,
    AdvancedSearchFilteringData: [],
    setAdvancedSearchFilteringData: () => { }
  });

export default DataContext;
