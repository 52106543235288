import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getVotingProfileTopSection,
  getInvestorDDLList,
  getVotingProfileBottomSection,
  getVotingTabDataCheck,
  getCompensationonlyfilter,
} from '../../../utils/investorVotingProfile-util';
import { AllowDownload } from '../../../utils/general-util';
import products from '../../../constants/ProductConstants';

export const getInvestorDDLListReq = createAsyncThunk('getInvestorDDLList', async (res) => {
  const response = await getInvestorDDLList(res);
  return response;
});

export const getVotingTabDataCheckReq = createAsyncThunk('getVotingTabDataCheckReq', async (res) => {
  const response = await getVotingTabDataCheck(res);
  return response;
});

export const getCompensationonlyfilerReq = createAsyncThunk('getCompensationonlyfilerReq', async (res) => {
  const response = await getCompensationonlyfilter(res);
  return response;
});

export const getVotingProfileTopSectionReq = createAsyncThunk('getVotingProfileTopSection', async (res) => {
  let response = [];
  const productsId = products.VOTING;
  // Trial User Check

  await AllowDownload(productsId); // Allow Download Option
  response = await getVotingProfileTopSection(res);

  response = {
    ...response,
  };
  return response;
});

export const getVotingProfileBottomSectionReq = createAsyncThunk('getVotingProfileBottomSection', async (res) => {
  let response = [];
  response = await getVotingProfileBottomSection(res);
  response = {
    ...response,
  };
  return response;
});

const votingProfileSlice = createSlice({
  name: 'investorVotingProfile',
  initialState: {
    ddlAllInvestor: [],
    ddlSetInvestor: {},
    getVotingProfileTopSectionReq: {},
    getVotingProfileBottomSectionReq: {},

    tableKeyDocument: [],
    tableProxyVotingSummary: [],
    tableVotingPolicyChanges: [],
    tableContacts: [],
    tableBoards: {},
    tableCommittes: {},
    tableRemuneration: {},
    tableStructure: {},
    tableGeneral: {},
    tableESG: {},
    tableVotingPolicy: {},
    tableNews: [],

    isLoadingVotingProfile: true,
    trialStatus: false,
    allowDownload: true,
    setSection: { status: false, id: 'PolicyChanges' },

    accessVotingProfile: false,
    compensationonlyfiler: {},
  },
  reducers: {
    handleSetInvestor: {
      reducer(state, action) {
        state.ddlSetInvestor = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleSetSection: {
      reducer(state, action) {
        state.setSection = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleResetVotingProfile: {
      reducer(state) {
        state.getVotingProfileTopSectionReq = {};
        state.getVotingProfileBottomSectionReq = {};
        state.ddlSetInvestor = undefined;
        state.votingProfile = {};
        state.tableKeyDocument = [];
        state.tableProxyVotingSummary = [];
        state.tableVotingPolicyChanges = [];
        state.tableContacts = [];
        state.tableBoards = {};
        state.tableCommittes = {};
        state.tableRemuneration = {};
        state.tableStructure = {};
        state.tableGeneral = {};
        state.tableESG = {};
        state.tableVotingPolicy = {};
        state.tableNews = [];
        state.isLoadingVotingProfile = true;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    [getInvestorDDLListReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.ddlAllInvestor = action.payload !== undefined ? action.payload.ddlAllInvestor : [];
      }
    },
    [getVotingProfileTopSectionReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.votingProfile = action.payload !== undefined ? action.payload.votingProfile : [];
        state.tableKeyDocument = action.payload !== undefined ? action.payload.tableKeyDocument : [];
        state.tableProxyVotingSummary = action.payload !== undefined ? action.payload.tableProxyVotingSummary : [];
        state.tableVotingPolicyChanges = action.payload !== undefined ? action.payload.tableVotingPolicyChanges : [];
        // state.tableContacts = action.payload !== undefined ? action.payload.tableContacts : [];
        // state.tableBoards = action.payload !== undefined ? action.payload.tableBoards : {};
        // state.tableCommittes = action.payload !== undefined ? action.payload.tableCommittes : {};
        // state.tableRemuneration = action.payload !== undefined ? action.payload.tableRemuneration : {};
        // state.tableStructure = action.payload !== undefined ? action.payload.tableStructure : {};
        // state.tableGeneral = action.payload !== undefined ? action.payload.tableGeneral : {};
        // state.tableESG = action.payload !== undefined ? action.payload.tableESG : {};
        // state.tableVotingPolicy = action.payload !== undefined ? action.payload.tableVotingPolicy : {};
        // state.tableNews = action.payload !== undefined ? action.payload.tableNews : [];

        state.TrialStatus = action.payload !== undefined ? action.payload.TrialStatus : false;
        state.allowDownload = action.payload !== undefined ? action.payload.allowDownload : false;
        state.isLoadingVotingProfile = action.payload === undefined;
      }
    },
    [getVotingProfileBottomSectionReq.fulfilled]: (state, action) => {
      if (action.payload) {
        // state.votingProfile = action.payload !== undefined ? action.payload.votingProfile : [];
        // state.tableKeyDocument = action.payload !== undefined ? action.payload.tableKeyDocument : [];
        // state.tableProxyVotingSummary = action.payload !== undefined ? action.payload.tableProxyVotingSummary : [];
        // state.tableVotingPolicyChanges = action.payload !== undefined ? action.payload.tableVotingPolicyChanges : [];
        state.tableContacts = action.payload !== undefined ? action.payload.tableContacts : [];
        state.tableBoards = action.payload !== undefined ? action.payload.tableBoards : {};
        state.tableCommittes = action.payload !== undefined ? action.payload.tableCommittes : {};
        state.tableRemuneration = action.payload !== undefined ? action.payload.tableRemuneration : {};
        state.tableStructure = action.payload !== undefined ? action.payload.tableStructure : {};
        state.tableGeneral = action.payload !== undefined ? action.payload.tableGeneral : {};
        state.tableESG = action.payload !== undefined ? action.payload.tableESG : {};
        state.tableVotingPolicy = action.payload !== undefined ? action.payload.tableVotingPolicy : {};
        state.tableNews = action.payload !== undefined ? action.payload.tableNews : [];

        state.TrialStatus = action.payload !== undefined ? action.payload.TrialStatus : false;
        state.allowDownload = action.payload !== undefined ? action.payload.allowDownload : false;

        state.isLoadingVotingProfile = action.payload === undefined;
      }
    },
    [getVotingTabDataCheckReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.accessVotingProfile = action.payload.VotingProfile && true;
      }
    },
    [getCompensationonlyfilerReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.compensationonlyfiler = action.payload !== undefined ? action.payload[0].Compensation_only_filer : [];
      }
    },
  },
});

export const { handleSetInvestor, handleSetSection, handleResetVotingProfile } = votingProfileSlice.actions;

export default votingProfileSlice.reducer;
