import { sanitizeUrl } from '@braintree/sanitize-url';
import config, { listActivistInvestorsForCompanyAiS, getActivismSummary_AiS } from '../config/server-config';
import { dateToISOString } from './general-util';
import { readFromCache, writeToCache } from './cache-util';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const company_search = async (searchData, cancelToken) => {
  const { nameSearch, limit, offset, quicksearch, isFullSearchResult } = searchData;
  try {
    const response = await getRequestFunction();
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }

  function getRequestFunction() {
    const sanitizedName = sanitizeUrl(nameSearch);
    if (isFullSearchResult) {
      return api.get(config.company_searchV3, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    if (quicksearch) {
      return api.get(config.companyPagedQuicksearchV2, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    return api.get(config.companyPagedSearchV2, {
      params: { nameSearch: sanitizedName, limit, offset },
      cancelToken: cancelToken,
    });
  }
};

export const ListMeetingDates = async (req) => {
  try {
    const response = await api.post(
      config.ListMeetingDates,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVotingData_allvotes_v2 = async (meeting_id, proposals, voteCast, cancelToken) => {
  try {
    const response = await api.post(
      config.GetVotingData_allvotes_v2,
      {
        meeting_id,
        proposals,
        voteCast,
      },
      {
        cancelToken: cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVotingData_rationale_meeting_against = async (req) => {
  try {
    const response = await api.post(
      config.GetVotingData_rationale_meeting_against,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVotingData_against_mgmt_summary = async (req) => {
  try {
    const response = await api.post(
      config.GetVotingData_against_mgmt_summary,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Historical_voting_summary = async (req) => {
  try {
    const response = await api.post(
      config.Historical_voting_summary,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvestor_voting_summary = async (req) => {
  try {
    const response = await api.post(
      config.GetInvestor_voting_summary,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListIssuerVotesAgainst = async (req) => {
  try {
    const response = await api.post(
      config.ListIssuerVotesAgainst,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetSelectedProposalsCountry = async (meeting_id) => {
  try {
    const response = await api.post(config.GetSelectedProposalsCountry, {
      meeting_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const BindgvVotingGrid = async (req) => {
  try {
    const response = await api.post(config.BindgvVotingGrid, {
      meetingId: req.meetingId,
      proposal_type_id: req.proposal_type_id,
      investor_search_id: req.investor_search_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getShareClasses = async (pid) => {
  try {
    const response = await api.post(config.GetShareClasses, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data.data;
    }
  } catch (e) {
    return false;
  }
};

export const getProposalList = async (meetingId, prevMeetingId, set_adjustment, cancelToken) => {
  try {
    const response = await api.post(
      config.getProposalList,
      {
        meetingId,
        prevMeetingId,
        set_adjustment,
      },
      {
        cancelToken: cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response;
    }
  } catch (e) {
    return false;
  }
};

export const MeetingQuickViewDynamicPivotv4 = async (meeting_id, proposal_id, GetLine, IsPDF, cancelToken) => {
  try {
    const response = await api.post(
      config.MeetingQuickViewDynamicPivotv4,
      {
        meeting_id,
        proposal_id,
        IsPDF,
        GetLine,
      },
      {
        cancelToken: cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVoteResults_v3 = async (req) => {
  try {
    const response = await api.post(
      config.GetVoteResults_v3,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetNoActionTrackInfo = async (req) => {
  try {
    const response = await api.post(
      config.GetNoActionTrackInfo,
      {
        index_id: req.index_id,
        start_date: dateToISOString(req.start_date),
        end_date: dateToISOString(req.end_date),
        proposal_type_top: req.proposal_type_top,
        proposal_type_sub: req.proposal_type_sub,
        proposal_type: req.proposal_type,
        industry_id: req.industry_id,
        proponent: req.proponent,
        Resolutions_Filter: req.Resolutions_Filter,
        meeting_id: req.meeting_id,
        company_search_id: req.company_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const noActionLettersDataExists = async (pid) => {
  try {
    const response = await api.post(config.noActionLettersDataExists, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data.data.length > 0;
    }
  } catch (e) {
    return false;
  }
};

// Activism Overview Graphs
export const GetActivismOverviewGraphs = async (req) => {
  try {
    const response = await api.post(
      config.getActivismOverviewGraphs,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// No Action Letter Proposal Detail
export const GetNoActionLetterProposalDetail = async (proposal_id) => {
  try {
    const response = await api.post(config.GetNoActionLetterProposalDetail, {
      proposal_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetIssuer_Meeting_stats = async (req) => {
  try {
    const response = await api.post(
      config.GetIssuer_Meeting_stats,
      {
        meeting_id: req.meetingId,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyProfile = async (pid) => {
  try {
    const response = await api.post(config.getCompanyProfile, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getIssuerLatestMeetingId = async (pid) => {
  try {
    const response = await api.post(config.getIssuerLatestMeetingId, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data.data[0];
    }
  } catch (e) {
    return false;
  }
};

export const getIssuerProfile = async (meeting_id, proposal_id) => {
  try {
    const response = await api.post(config.getIssuerProfile, {
      meeting_id,
      proposal_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListTopTwentyActivistActivity = async () => {
  try {
    const response = await api.post(config.listTopTwentyActivistActivity, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const LatestActivistDemands = async () => {
  try {
    const response = await api.post(config.latestActivistDemands);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const TimeLines = async () => {
  try {
    const response = await api.post(config.listTop8TimeLine);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ActiveActivists_Trends_Overview = async () => {
  try {
    const response = await api.post(config.activeActivists_Trends_Overview);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const CompaniesTargeted_Trends_Overview = async () => {
  try {
    const response = await api.post(config.companiesTargeted_Trends_Overview);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// sample data - trial User
export const GetVotingListTrialUser = async () => {
  try {
    const cachedData = readFromCache(config.GetVotingListTrialUser);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.GetVotingListTrialUser, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetVotingListTrialUser, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

export const GetActivismListTrialUser = async () => {
  try {
    const cachedData = readFromCache(config.GetActivismListTrialUser);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.GetActivismListTrialUser, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetActivismListTrialUser, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

export const GetVulnerabilityListTrialUser = async () => {
  try {
    const cachedData = readFromCache(config.GetVulnerabilityListTrialUser);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.GetVulnerabilityListTrialUser, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetVulnerabilityListTrialUser, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

export const GetActivistShortListTrialUser = async () => {
  try {
    const cachedData = readFromCache(config.GetActivistShortListTrialUser);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.GetActivistShortListTrialUser, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetActivistShortListTrialUser, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

export const GetGovernanceListTrialUser = async () => {
  try {
    const cachedData = readFromCache(config.GetGovernanceListTrialUser);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.GetGovernanceListTrialUser, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.GetGovernanceListTrialUser, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

export const listTopTwentyActivistActivity = async () => {
  try {
    const cachedData = readFromCache(config.listTopTwentyActivistActivity);

    if (cachedData) {
      return cachedData;
    }
    const response = await api.post(config.listTopTwentyActivistActivity, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      writeToCache(config.listTopTwentyActivistActivity, response.data);
      return response.data;
    }
    return [];
  } catch (e) {
    return false;
  }
};

// AIV

export const PIGetIssuer = async (req) => {
  try {
    const response = await api.post(
      config.PIGetIssuer,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const VunGetGovDirectorInfoV4 = async (req) => {
  try {
    const response = await api.post(
      config.VunGetGovDirectorInfoV4,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const VunSummaryScore = async (req) => {
  try {
    const response = await api.post(
      config.vunSummaryScore,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const VunListNewsArticlesForIssuer = async (req) => {
  try {
    const response = await api.post(
      config.VunListNewsArticlesForIssuer,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// ACTIVIST SHORT OVERVIEW: Calls are prered by ensuring the json structure keys are correct, sets URI from server-config and bearer from storage
export const GetAiSCompDisclosedShortPositions = async (req) => {
  try {
    const response = await api.post(
      config.getAiSCompDisclosedShortPositions,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHistoricShortPositions = async (req) => {
  try {
    const response = await api.post(
      config.getHistoricShortPositions,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListActivistInvestorsForCompany_NEW_ais = async (company_id) => {
  try {
    const response = await api.post(config.listActivistInvestorsForCompany_NEW_ais, {
      company_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const VunList10QAnd10KForIssuer = async (req) => {
  try {
    const response = await api.post(
      config.VunList10QAnd10KForIssuer,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV OWNERSHIP

export const PIGetShareholdersTop10 = async (req) => {
  try {
    const response = await api.post(
      config.PIGetShareholdersTop10,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV OWNERSHIP : ACTIVIST INVESTORS
export const GetShareHoldersActivistOnly = async (company_id) => {
  try {
    const response = await api.post(config.GetShareHoldersActivistOnly, {
      company_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetAiSCompTotalShortPositions = async (req) => {
  try {
    const response = await api.post(
      config.getAiSCompTotalShortPositions,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV AGM Voting Most Recent
export const PIGetMostRecentAGMOrPCMeetingIdWithVotes = async (req) => {
  try {
    const response = await api.post(
      config.PIGetMostRecentAGMOrPCMeetingIdWithVotes,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV OWNERSHIP : ACTIVIST SUMMARY
export const GetActivismSummary = async (company_id) => {
  try {
    const response = await api.post(config.GetActivismSummary, {
      company_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Adm_Check_PID = async (pid) => {
  try {
    const response = await api.post(config.adm_Check_PID, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV AGM Voting Results
export const PIGetVoteResults = async (meeting_id) => {
  try {
    const response = await api.post(config.PIGetVoteResults, {
      meeting_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV AGM VOTING Remuneration
export const VunGetAllRemunerationMediansAndMADMs = async (req) => {
  try {
    const response = await api.post(config.VunGetAllRemunerationMediansAndMADMs, {
      pid: req.pid,
      cmp_search_id: req.cmp_search_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// VOTING REMUNERATION ONE YEAR BACK VunGetAllRemunerationMediansAndMADMsYearAroundDate
export const VunGetAllRemunerationMediansAndMADMsYearAroundDate = async (req) => {
  try {
    const response = await api.post(config.VunGetAllRemunerationMediansAndMADMsYearAroundDate, {
      pid: req.pid,
      meeting_date: req.meeting_date,
      cmp_search_id: req.cmp_search_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AGM VOTING MEDIAN FOOTER
export const VunGetAllDirectorMediansAndMADMs = async (req) => {
  try {
    const response = await api.post(config.VunGetAllDirectorMediansAndMADMs, {
      pid: req.pid,
      cmp_search_id: req.cmp_search_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AIV Top 10 footer OWNERSHIP
export const GetVunGetAllInstitutionalMediansAndMADMs = async (req) => {
  try {
    const response = await api.post(
      config.GetVunGetAllInstitutionalMediansAndMADMs,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// ACTIVIST INSIGHT SHORTS CAMPAIGN
export const ListActivistInvestorsForCompanyAiS = async (req) => {
  try {
    const response = await api.post(
      listActivistInvestorsForCompanyAiS,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      if (response.data.data.length > 0) {
        return {
          data: response.data.data.sort((a, b) => new Date(b.date_first_invested) - new Date(a.date_first_invested)),
        };
      }
      return { data: response.data.data };
    }
  } catch (e) {
    return false;
  }
};

export const GetVunGetAllActivistMediansAndMADMs = async (req) => {
  try {
    const response = await api.post(
      config.GetVunGetAllActivistMediansAndMADMs,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVulnerabilityScoreOverTime = async (req) => {
  try {
    const response = await api.post(
      config.getVulnerabilityScoreOverTime,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVulnerabilityPrankOverTime = async (req) => {
  try {
    const response = await api.post(
      config.getVulnerabilityPrankOverTime,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivismSummary_AiS = async (req) => {
  try {
    const response = await api.post(
      getActivismSummary_AiS,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return e;
  }
};

// AiG
// AiG Shareholder proposals
export const GetAIG_ShareholderProposals_v2 = async (req) => {
  try {
    const response = await api.post(
      config.getAIG_ShareholderProposals_v2,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AiG Latest Filings

export const GetGovCompanyDirector503 = async (req) => {
  try {
    const response = await api.post(
      config.getGovCompanyDirector503,
      {
        pid: req.pid,
        print: req.print,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyDirector502_v2 = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyDirector502_v2,
      {
        pid: req.pid,
        print: req.print,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyDirector507_v2 = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyDirector507_v2,
      {
        pid: req.pid,
        print: req.print,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyDirectorshort_v2 = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyDirectorshort_v2,
      {
        pid: req.pid,
        print: req.print,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyDirector10k_v2 = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyDirector10k_v2,
      {
        pid: req.pid,
        print: req.print,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AiG Company Director
export const GetGovDirectorInfo = async (req) => {
  try {
    const response = await api.post(
      config.getGovDirectorInfo,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get_Gov_Independent_Graph_Data = async (req) => {
  try {
    const response = await api.post(
      config.get_Gov_Independent_Graph_Data,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get_Gov_Tenure_Graph_Data = async (req) => {
  try {
    const response = await api.post(
      config.get_Gov_Tenure_Graph_Data,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get_Gov_Gender_Graph_Data = async (req) => {
  try {
    const response = await api.post(
      config.get_Gov_Gender_Graph_Data,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get_interlocking_directors_JSON_v2 = async (req) => {
  try {
    const response = await api.post(
      config.get_interlocking_directors_JSON_v2,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetBoardNewsHeadlines = async (req) => {
  try {
    const response = await api.post(
      config.getBoardNewsHeadlines,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComDirProfPast = async (req) => {
  try {
    const response = await api.post(
      config.getComDirProfPast,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComDirProf = async (req) => {
  try {
    const response = await api.post(
      config.getComDirProf,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComDirProfPastHeaderCol = async (pid) => {
  try {
    const response = await api.post(config.getComDirProfPastHeaderCol, {
      pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AiG Poison Pill
export const GetDetPoisonPill = async (req) => {
  try {
    const response = await api.post(
      config.getDetPoisonPill,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetItem303Material = async (req) => {
  try {
    const response = await api.post(
      config.getItem303Material,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDetPoisonPillTopHdr = async (req) => {
  try {
    const response = await api.post(
      config.getDetPoisonPillTopHdr,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// AiG Byline
export const GetCompanyDirector503 = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyDirector503,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    if (!api.isCancel()) {
      return false;
    }
  }
};

export const Get_Bylaws_Charter_GovGuidelines_Data = async (req) => {
  try {
    const response = await api.post(
      config.get_Bylaws_Charter_GovGuidelines_Data,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    if (!api.isCancel()) {
      return false;
    }
  }
};

// AiG Complience
export const GetCompFillingType = async (req) => {
  try {
    const response = await api.post(
      config.getCompFillingType,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompStatement = async (req) => {
  try {
    const response = await api.post(
      config.getCompStatement,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComplianceComparisonIndexes = async (req) => {
  try {
    const response = await api.post(
      config.getComplianceComparisonIndexes,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComplianceVotinDissent = async (req) => {
  try {
    const response = await api.post(
      config.getComplianceVotinDissent,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// #region Ownership Long/Short - Company
export const getLatestOwnershipDateList = async () => {
  try {
    const response = await api.post(config.getLatestOwnershipDateList, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOwnershipLongFundData = async (req) => {
  try {
    const response = await api.post(config.getOwnershipLongFundData, {
      pid: req.pid,
      filterRecords: req.filterRecords,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOwnershipLongInvestorData = async (req) => {
  try {
    const response = await api.post(
      config.getOwnershipLongInvestorData,
      {
        pid: req.pid,
        period_of_report: req.period_of_report,
        change_comparison: req.change_comparison,
        filterRecords: req.filterRecords,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOwnershipShortInvestorData = async (req) => {
  try {
    const response = await api.post(
      config.getOwnershipShortInvestorData,
      {
        pid: req.pid,
        filterRecords: req.filterRecords,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOwnershipShortFundData = async (req) => {
  try {
    const response = await api.post(
      config.getOwnershipShortFundData,
      {
        pid: req.pid,
        filterRecords: req.filterRecords,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getownershipCompanyDummyData = async () => {
  try {
    const response = await api.post(config.OwnershipCompanyDummyData, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// #endregion

// Voting Overview ListVotingOwnershipForProposal_v2
export const ListVotingOwnershipForProposal_v2 = async (req) => {
  try {
    const meeting_id_int = req.meeting_id ? Number(req.meeting_id) : null;
    const response = await api.post(
      config.listVotingOwnershipForProposal_v2,
      {
        meeting_id: meeting_id_int,
        proposal_id: req.proposal_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Get_OtherBoards
export const Get_OtherBoards = async (proposal_id) => {
  try {
    const response = await api.post(config.get_OtherBoards, {
      proposal_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// GetVotingData_rationale
export const GetVotingData_rationale = async (meeting_id, proposal_id) => {
  try {
    const response = await api.post(config.getVotingData_rationale, {
      meeting_id,
      proposal_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ListVotingAndOwnerhipForProposal_insightia = async (
  meeting_id,
  proposal_id,
  prev_meeting_id,
  prev_proposal_id,
  vote_type,
) => {
  try {
    const Prev_meeting_id = prev_meeting_id !== undefined ? prev_meeting_id : null;
    const Prev_proposal_id = prev_proposal_id !== undefined ? prev_proposal_id : null;
    const response = await api.post(config.listVotingAndOwnerhipForProposal_insightia, {
      meeting_id,
      proposal_id,
      Prev_meeting_id,
      Prev_proposal_id,
      vote_type,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getMeetingURLs = async (req) => {
  try {
    const response = await api.post(
      config.getMeetingURLs,
      {
        meeting_id: Number(req.meeting_id),
        meeting_date: new Date(req.meeting_date),
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response;
    }
  } catch (e) {
    return false;
  }
};

export const listCompanyFilingsByActivist_v2 = async (
  req,
  // company_id,
  // activist_id,
  // longShort
) => {
  try {
    const response = await api.post(
      config.listCompanyFilingsByActivist_v2,
      {
        company_id: req.company_id,
        activist_id: req.activist_id,
        longShort: req.longShort,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetAdvisorSearchData = async (searchName) => {
  try {
    const response = await api.post(config.getAdvisorSearchData, {
      searchName,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// Company> Activism > Activist campaigns
//
export const GetCompanyOverviewProfile = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyOverviewProfile,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyStockEvents = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyStockEvents,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyPeerGroupOverview = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyPeerGroupOverview,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
        activist_vulnerability: req.activist_vulnerability,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetPeerGroupName = async (req) => {
  try {
    const response = await api.post(
      config.getPeerGroupName,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetStockValues_graph = async (req) => {
  try {
    const response = await api.post(
      config.getStockValues_graph,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getActivistCampaignsDataList = async (req) => {
  try {
    const response = await api.post(config.getActivistCampaignsDataList, {
      indiv_campaigns: req.indiv_campaigns,
      pid: req.pid,
      show_other_campaigns: req.show_other_campaigns,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const getActivistCampaignsDataListV2 = async (req) => {
  try {
    const response = await api.post(
      config.getActivistCampaignsDataListV2,
      {
        indiv_campaigns: req.indiv_campaigns,
        pid: req.pid,
        show_other_campaigns: req.show_other_campaigns,
        resultset: req.resultset !== undefined ? req.resultset : null,
        isOverviewPage: req.isOverviewPage,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetLollipops_graph = async (req) => {
  try {
    const response = await api.post(
      config.getLollipops_graph,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const ActivistCampiagnDummyData = async () => {
  try {
    const response = await api.post(config.ActivistCampiagnDummyData, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Company> Governance> Overview
export const getGovOverview_meetingInfo_Quickview_StockData = async (req) => {
  try {
    const response = await api.post(
      config.getGovOverview_meetingInfo_Quickview_StockData,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getBoardAndDirectorsIndexDDL = async (req) => {
  try {
    const response = await api.post(
      config.getBoardAndDirectorsIndexDDL,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    if (!api.isCancel()) {
      return false;
    }
  }
};

export const getComparisionTables = async (req) => {
  try {
    const response = await api.post(
      config.getComparisionTables,
      {
        pid: req.pid,
        filterindex: req.filterindex,
        isPDF: req.isPDF,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistInvestorsForCompany = async (req) => {
  try {
    const response = await api.post(
      config.getActivistInvestorsForCompany,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistNotifiedHolding = async (req) => {
  try {
    const response = await api.post(
      config.getActivistNotifiedHolding,
      {
        company_id: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const companyGovernanceOverviewDummyData = async () => {
  try {
    const response = await api.post(config.CompanyGovernanceOverviewDummyData, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getActivistShortModuleAccess = async (req) => {
  try {
    const response = await api.post(config.getActivistShortModuleAccess, {
      pid: req,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const addTriallog = async (req) => {
  try {
    const response = await api.post(config.addTriallog, {
      user_id: req.user_id,
      type: req.type,
      id: req.id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const sendMailToTeam = async (req) => {
  try {
    const response = await api.post(config.sendMailToTeam, {
      user_id: req.user_id,
      email: req.email,
      url: req.url,
      subject: req.subject,
      number_profile: req.number_profile,
      trialUser: req.trialUser,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOwnershipLongShortInvestorDataCheck = async (req) => {
  try {
    const response = await api.post(config.getOwnershipLongShortInvestorDataCheck, {
      pid: req.pid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistShortCampaignAdvisersData = async (req) => {
  try {
    const response = await api.post(
      config.getActivistShortCampaignAdvisersData,
      {
        campaign_id: req.campaign_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetInvestorIdFromCampaignIdData = async (req) => {
  try {
    const response = await api.post(
      config.getInvestorIdFromCampaignId,
      {
        campaign_id: req.campaign_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetComDirUpcoming = async (req) => {
  try {
    const response = await api.post(
      config.getComDirUpcoming,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAdmGetCompanyShell_spac = async (req) => {
  try {
    const response = await api.post(
      config.getAdmGetCompanyShell_spac,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetPeerGroupDefaultName = async (req) => {
  try {
    const response = await api.post(
      config.getPeerGroupDefaultName,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const UpdateCompanyVunScoreData = async (req) => {
  try {
    const response = await api.post(
      config.UpdateCompanyVunScore,
      {
        pid: req.pid,
        cmp_search_id: req.cmp_search_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// ACTIVIST SHORT OVERVIEW, FDA RECALLS
export const GetFDAProductRecallListData = async (req) => {
  try {
    const response = await api.post(
      config.GetFDAProductRecallListData,
      {
        companyId: req.company_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getHistoricalGovernance = async (req) => {
  try {
    const response = await api.post(
      config.GetHistoricalGovernance,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompanyActivisamTabDataCheck = async (res) => {
  try {
    const response = await api.post(config.getCompanyActivisamTabDataCheck, {
      company_id: res.company_id,
      activist_id: res.activist_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const getSplitVotingDetails = async (req) => {
  try {
    const response = await api.post(config.getSplitVotingDetails, {
      meeting_id: req.meeting_id,
      proposal_id: req.proposal_id,
      prev_meeting_id: req.prev_meeting_id,
      prev_proposal_id: req.prev_proposal_id,
      investor_id: req.investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetVotingData_rationale_meeting = async (req) => {
  try {
    const response = await api.post(
      config.GetVotingData_rationale_meeting,
      {
        meeting_id: req.meeting_id,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompensationPerformanceMetricBreakDown = async (req) => {
  try {
    const response = await api.post(
      config.getCompensationPerformanceMetricBreakDown,
      {
        pid: req.pid,
        director_appointment_id: req.director_appointment_id,
        year: req.year,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationExecutivePayData = async (req) => {
  try {
    const response = await api.get(config.GetCompensationExecutivePayDataV2, {
      params: {
        pid: req.pid,
      },
      cancelToken: req.cancelToken,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompensationOverviewSummaryDetails = async (req) => {
  try {
    const response = await api.get(config.getCompensationOverviewSummaryDetailsV2, {
      params: {
        pid: req.pid,
      },
      cancelToken: req.cancelToken,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationOverviewExecutiveAndDirectorDetails = async (req) => {
  try {
    const response = await api.get(config.getCompensationOverviewExecutiveAndDirectorDetailsV2, {
      params: {
        pid: req.pid,
      },
      cancelToken: req.cancelToken,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompensatioCompanyolicyDetailsHighestPaidExecutive = async (req) => {
  try {
    const response = await api.post(
      config.getCompensatioCompanyolicyDetailsHighestPaidExecutive,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationPolicyDetails = async (req) => {
  try {
    const response = await api.post(
      config.getCompensationPolicyDetails,
      {
        pid: req.pid,
        year: req.year,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationPolicyHighestPaidExecutiveData = async (req) => {
  try {
    const response = await api.post(
      config.getCompensationPolicyHighestPaidExecutiveData,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationNonExecutivePay = async (req) => {
  try {
    const response = await api.post(
      config.GetCompensationNonExecutivePay,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationHighestPaidExe = async (req) => {
  try {
    const response = await api.post(
      config.GetCompensationHighestPaidExe,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetCompensationReportYears = async (req) => {
  try {
    const response = await api.post(
      config.GetCompensationReportYears,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//------------Company > overview > ESG
export const GetCompanyOverviewESG = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyOverviewESG,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//------------------------company > Compensation > overview
export const GetCurrencyData = async (req) => {
  try {
    const response = await api.post(
      config.getCurrencyData,
      {
        pid: req.pid,
        lable: req.lable,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get_Bylaws_Charter_GovGuidelines_Data_For_PDF = async (req) => {
  try {
    const response = await api.post(
      config.get_Bylaws_Charter_GovGuidelines_Data_For_PDF,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    if (!api.isCancel()) {
      return false;
    }
  }
};

export const getCompanyOverviewSummaryPDF = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyOverviewSummaryPDF,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// getCompanyActivismReportPDF
export const getCompanyActivismReportPDF = async (req) => {
  try {
    const response = await api.post(
      config.getCompanyActivismReportPDF,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// getActivistCampaignsCharacteristicsDetailsPDF
export const getActivistCampaignsCharacteristicsDetailsPDF = async (req) => {
  try {
    const response = await api.post(
      config.getActivistCampaignsCharacteristicsDetailsPDF,
      {
        pid: req.pid,
      },
      {
        //

        //
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// GetActivistCampaignsDetailsPDF
export const getActivistCampaignsDetailsPDF = async (req) => {
  try {
    const response = await api.post(
      config.getActivistCampaignsDetailsPDF,
      {
        pid: req.pid,
      },
      {
        //

        //
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
//getActivistCampaignsShareholderDetailsPDF
export const GetActivistCampaignsShareholderDetailsPDF = async (req) => {
  try {
    const response = await api.post(
      config.getActivistCampaignsShareholderDetailsPDF,
      {
        pid: req.pid,
      },
      {
        //

        //
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistInvestorForCompanyActivistShortPDF = async (req) => {
  try {
    const response = await api.post(
      config.getActivistInvestorForCompanyActivistShortPDF,
      {
        company_id: req.company_id,
      },
      {
        //

        //
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//Company> Activism> Demands
export const GetCompanyGBRCampaigns_v2List = async (company_id) => {
  try {
    const response = await api.post(config.getCompanyGBRCampaigns_v2List, {
      company_id: company_id,
      LongShort: 'B',
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCompanyCampaignsbyActivistList = async (company_id) => {
  try {
    const response = await api.post(config.getCompanyCampaignsbyActivistList, {
      company_id: company_id,
      activist_id: null,
      LongShort: 'L',
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
