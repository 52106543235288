import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

// import { history } from '../utils/navigation-util';

export const getMagazinesReportList = async (product_id, article_type_list) => {
  try {
    const response = await api.post(
      config.getMagazinesReportList,
      {
        product_id,
        article_type_list,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetMagazine_ProxyOrSpecialReports_insightiaList = async (Article_type_id) => {
  try {
    const response = await api.post(
      config.GetMagazine_ProxyOrSpecialReports_insightiaList,
      {
        Article_type_id,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const SearchAiMMagazineText = async (search) => {
  try {
    const response = await api.post(
      config.searchAiMMagazineText,
      {
        search,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetAllMagazineArchives = async (req) => {
  try {
    const response = await api.post(
      config.getAllMagazineArchives,
      {
        issue_year: null,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAllMagazineArchivesTabs = async (req) => {
  try {
    const response = await api.post(
      config.GetAllMagazineArchivesTab,
      {
        article_types: req.articleTypes,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetAllMagazineReport = async (req) => {
  try {
    const response = await api.post(
      config.getAllMagazineArchives,
      {
        issue_year: req.issue_year,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
