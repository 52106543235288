import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  GetSummary_ESGPeerCard,
  GetActivistCampaignESGdata,
  GetESGBreackDowndata,
} from '../../../utils/companyESG-util';

export const getSummaryESGPeerCardReq = createAsyncThunk(
  'getSummaryESGPeerCardReq',
  async (obj) => {
    const response = await GetSummary_ESGPeerCard(obj);
    return response;
  }
);
export const GetESGBreackDowndataReq = createAsyncThunk(
  'GetESGBreackDowndataReq',
  async (req) => {
    const response = await GetESGBreackDowndata(req);
    return response;
  }
);
export const getActivistCampaignESGdataReq = createAsyncThunk(
  'getActivistCampaignESGdataReq',
  async (obj) => {
    const response = await GetActivistCampaignESGdata(obj);
    return response;
  }
);

const CompanyESGSlice = createSlice({
  name: 'esgSlice',
  initialState: {
    infoESGPeerCard: [],
    filterCompanyDataESGPeerCard: [],
    labelESGPeerCard: '',
    table_ActivistCampaignSummary: [],
    header_ActivistCampaignSummary: [],
    isLoading: true,
    tblESGbreackdown: undefined,
    tblESGbreackdownOrg: undefined,
    tblESGbreackdownFooterData: undefined,
    tblESGbreackdownFooterDataOrg: undefined,
    isDataRelevance: false,
  },
  reducers: {
    handleResetESGOverview: {
      reducer(state) {
        state.infoESGPeerCard = [];
        state.filterCompanyDataESGPeerCard = [];
        state.labelESGPeerCard = '';
        state.table_ActivistCampaignSummary = [];
        state.header_ActivistCampaignSummary = [];
        state.isLoading = true;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleReset: {
      reducer(state, action) {
        state.tblESGbreackdown = undefined;
        state.tblESGbreackdownFooterData = undefined;
      },
      preapare(e) {
        return {
          payload: e,
        };
      },
    },
    handleResetData: {
      reducer(state, action) {
        state.isDataRelevance = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [getSummaryESGPeerCardReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.infoESGPeerCard =
          action.payload !== undefined ? action.payload.data : [];
        state.filterCompanyDataESGPeerCard =
          action.payload !== undefined ? action.payload.filterCompanyData : [];
        state.labelESGPeerCard =
          action.payload !== undefined ? action.payload.label : '';
      }
    },
    [getActivistCampaignESGdataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.table_ActivistCampaignSummary = action.payload
          ? action.payload.data
          : [];
        state.header_ActivistCampaignSummary = action.payload
          ? action.payload.header_ActivistCampaignSummary
          : [];
      }
      state.isLoading = false;
    },
    [GetESGBreackDowndataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.tblESGbreackdown = action.payload.tblData
          ? action.payload.tblData
          : [];
        state.tblESGbreackdownOrg = action.payload.tblData
          ? action.payload.tblData
          : [];
        state.tblESGbreackdownFooterData = action.payload.footerData
          ? action.payload.footerData
          : [];
        state.tblESGbreackdownFooterDataOrg = action.payload.footerData
          ? action.payload.footerData
          : [];
      } else {
        state.tblESGbreackdown = [];
        state.tblESGbreackdownFooterData = [];
      }
    },
  },
});

export const { handleResetData, handleReset, handleResetESGOverview } =
  CompanyESGSlice.actions;

export default CompanyESGSlice.reducer;
