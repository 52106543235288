export const FEATURE_GL_P4P_SNAPSHOT = 1;
export const FEATURE_ECM_SNAPSHOT = 5;
export const FEATURE_SHARES_VOTED_DATE_UPDATES = 6;
export const FEATURE_VOTING_DISCLOSURE = 6;

export const FEATURE_ACCESS_ENABLED = 1;
export const FEATURE_ACCESS_DISABLED = 2;
export const FEATURE_ACCESS_LIMITED = 3;

export const FEATURE_ACCESS_HIDDEN = 0;

export default {
  FEATURE_GL_P4P_SNAPSHOT,
  FEATURE_ACCESS_DISABLED,
  FEATURE_ACCESS_ENABLED,
  FEATURE_ACCESS_HIDDEN,
  FEATURE_ACCESS_LIMITED,
  FEATURE_VOTING_DISCLOSURE,
  FEATURE_ECM_SNAPSHOT,
  FEATURE_SHARES_VOTED_DATE_UPDATES,
};
