import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { COMPENSATION } from '../constants/ProductConstants';
import { TokenDecode } from './general-util';
import api from '../api';

export const GetP4PAuth = async (req) => {
    try {
      const tokenDecode = await TokenDecode();
      const response = await api.post(
        config.getP4PAuth,
        { email: tokenDecode.UserEmail }
      );
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } catch (e) {
      return false;
    }
};

export default function () { console.log('No Route Found!'); }
