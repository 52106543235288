import React, { useEffect, useState } from 'react';
import { lensThemeOptions } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/index';
import { AtlasThemeProvider } from '@diligentcorp/atlas-theme-mui';
import { useFeatureToggle } from './features/v2/FeatureToggles/hooks';
import { Cell } from 'recharts';

export function LensWrapper({ children }) {
  const { IS_LENS_FONT } = useFeatureToggle();
  const [themeOptions, setThemeOptions] = useState(lensThemeOptions);

  useEffect(() => {
    setThemeOptions((prevOptions) => ({
      ...prevOptions,
      components: {
        ...prevOptions.components,
        MuiFormControl: {},
        MuiSelect: {},
        MuiDataGrid: {
          ...prevOptions.components.MuiDataGrid,
          defaultProps: {},
          styleOverrides: {
            ...prevOptions.components.MuiDataGrid.styleOverrides,
            root: {},
          },
        },
      },
      typography: {
        ...prevOptions.typography,
        fontFamily: IS_LENS_FONT ? lensThemeOptions.typography.fontFamily : undefined,
      },
    }));
  }, [IS_LENS_FONT]);

  return <AtlasThemeProvider themeOptions={themeOptions}>{children}</AtlasThemeProvider>;
}
