export const MODULE_COMPANY = 'company';
export const MODULE_INVESTOR = 'investor';
export const MODULE_ADVISERS = 'advisers';
export const MODULE_PEOPLE = 'people';

export const ACTIVISM = 'Ca';
export const ACTIVIST_SHORTS = 'Cs';
export const VOTING = 'Cv';
export const GOVERNANCE = 'Cg';
export const VULNERABILITY = 'Cvu';
export const TRIAL_PID = 42;
export const TRIAL_MEETINGID = 1752562;
export const TRIAL_COMPLIANCE_PID = 6457;

export const INVESTOR_ACTIVISM = 'Ia';
export const INVESTOR_ACTIVIST_SHORTS = 'Ias';
export const INVESTOR_VOTING = 'Ivo';
export const TRIAL_INVESTORID_VOTING_AND_ACTIVISM = 607;
export const TRIAL_INVESTORID_ACTIVIST_SHORTS = 6027;

export const ADVISERS_ACTIVISM = 'Aa';
export const ADVISERS_ACTIVIST_SHORTS = 'As';
export const ADVISERS_VOTING = 'Av';
export const TRIAL_ADVISERS_COMPANYID = 4133;

export const PEOPLE_GOVERNANCE = 'Pg';
export const PEOPLE_COMPENSATION = 'Pco';
export const COMPANY_COMPENSATION = 'Cco';

export const COMPANY_ESG = 'Cesg';

export const TRIAL_GOVERNANCE_STATE = 'Delaware';
export const TRIAL_DIRECTOR_ID = 63954;

export const TRIAL_USER = 2;
export const NOT_TRIAL_USER = 4;

export const MAX_LIMIT_COMPANY_IDS = 50;
export const MAX_LIMIT_INVESTOR_IDS = 20;
export const MAX_LIMIT_ADVISOR_IDS = 20;
export const MAX_LIMIT_PEOPLE_IDS = 50;

export default {
  MODULE_COMPANY,
  MODULE_INVESTOR,
  MODULE_ADVISERS,
  MODULE_PEOPLE,
  ACTIVISM,
  ACTIVIST_SHORTS,
  VOTING,
  GOVERNANCE,
  VULNERABILITY,
  INVESTOR_ACTIVISM,
  INVESTOR_ACTIVIST_SHORTS,
  INVESTOR_VOTING,
  TRIAL_INVESTORID_VOTING_AND_ACTIVISM,
  TRIAL_INVESTORID_ACTIVIST_SHORTS,
  ADVISERS_ACTIVISM,
  ADVISERS_ACTIVIST_SHORTS,
  ADVISERS_VOTING,
  TRIAL_ADVISERS_COMPANYID,
  PEOPLE_GOVERNANCE,
  PEOPLE_COMPENSATION,
  COMPANY_COMPENSATION,
  TRIAL_DIRECTOR_ID,
  TRIAL_USER,
  NOT_TRIAL_USER,
  TRIAL_PID,
  TRIAL_MEETINGID,
  TRIAL_COMPLIANCE_PID,
  MAX_LIMIT_COMPANY_IDS,
  MAX_LIMIT_INVESTOR_IDS,
  MAX_LIMIT_ADVISOR_IDS,
  MAX_LIMIT_PEOPLE_IDS,
  COMPANY_ESG,
};
