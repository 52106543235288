import { sanitizeUrl } from '@braintree/sanitize-url';
import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const People_search = async (nameSearch, quicksearch, limit, offset, cancelToken, isFullSearchResult) => {
  try {
    const response = await getRequestFunction();
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  function getRequestFunction() {
    const sanitizedName = sanitizeUrl(nameSearch);
    if (isFullSearchResult) {
      return api.get(config.people_searchV2, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    if (quicksearch) {
      return api.get(config.people_pagedQuicksearchV2, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    return api.get(config.people_pagedSearchV2, {
      params: { nameSearch: sanitizedName, limit, offset },
      cancelToken: cancelToken,
    });
  }
};

export const GetPropleProfile = async (director_id) => {
  try {
    const response = await api.post(config.getPropleProfile, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getDirectorPersonInfo = async (director_id) => {
  try {
    const response = await api.post(config.getDirectorPersonInfo, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getDirectorAppointmentInfo = async (director_id, current) => {
  try {
    const response = await api.post(config.getDirectorAppointmentInfo, {
      director_id,
      current,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getDirectorAppointmentInfo2 = async (director_id, current) => {
  try {
    const response = await api.post(config.getDirectorAppointmentInfo2, {
      director_id,
      current,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDirectorsDetails = async (director_id) => {
  try {
    const response = await api.post(config.getDirectorsDetails, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetDirectorContactData = async (director_id) => {
  try {
    const response = await api.post(config.getDirectorContactData, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const GetDirectorOnBoardData = async (director_id) => {
  try {
    const response = await api.post(config.getDirectorOnBoardData, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
// GetGanttChartSampleData
export const getGanttChartSampleData = async (director_id) => {
  try {
    const response = await api.post(config.GetGanttChartSampleData, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const getDirectorCurrentPastInfo = async (director_id) => {
  try {
    const response = await api.post(config.GetDirectorCurrentPastInfo, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const getActivistEmployee = async (director_id) => {
  try {
    const response = await api.post(config.GetActivistEmployee, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const getSharedDirectorships = async (director_id) => {
  try {
    const response = await api.post(config.GetSharedDirectorships, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
//People Compensation
export const getIndividualGrantedPeopleCompensation = async (director_id) => {
  try {
    const response = await api.post(config.GetIndividualGrantedPeopleCompensation, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

//people Overview
export const getPeopleOverview = async (director_id) => {
  try {
    const response = await api.post(config.getPeopleOverview, {
      director_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
