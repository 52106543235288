export const MY_ALERT_ACTIVISM_NEWS_ALERT_OPTION_ID = 1;
export const MY_ALERT_SHORT_ACTIVISM_NEWS_ALERT_OPTION_ID = 3;
export const MY_ALERT_ACTIVISM_FILING_ALERT_OPTION_ID = 2;
export const MY_ALERT_ACTIVIST_DEMANDS_ALERT_OPTION_ID = 20;

export const MY_ALERT_TYPE_ALERT = 1;
export const MY_ALERT_TYPE_PDF = 2;

export const MY_ALERT_OPTION_ID_ONE = 1;
export const MY_ALERT_OPTION_ID_TWO = 2;
export const MY_ALERT_OPTION_ID_THREE = 3;
export const ALERT_SUBOPTION_ACTIVIST_ACTION = 1;
export const ALERT_SUBOPTION_ACTIVISM_EVENTS = 2;
export const ALERT_SUBOPTION_FILING_TYPE = 3;
export const ALERT_SUBOPTION_SHORT_SELLER_ACTION = 4;
export const ALERT_SUBOPTION_SHORT_EVENTS = 5;
export const ALERT_SUBOPTION_GOV_POSITION = 6;
export const ALERT_SUBOPTION_GOV_APPOINT_CHANGE = 7;
export const ALERT_SUBOPTION_GOV_AMEND_CHANGE = 8;
export const ALERT_SUBOPTION_ACTIVISM_DEMAND_TYPE = 13;

export default {
  MY_ALERT_ACTIVISM_NEWS_ALERT_OPTION_ID,
  MY_ALERT_ACTIVISM_FILING_ALERT_OPTION_ID,
  MY_ALERT_SHORT_ACTIVISM_NEWS_ALERT_OPTION_ID,
  MY_ALERT_TYPE_ALERT,
  MY_ALERT_TYPE_PDF,

  MY_ALERT_OPTION_ID_ONE,
  MY_ALERT_OPTION_ID_TWO,
  MY_ALERT_OPTION_ID_THREE,
  MY_ALERT_ACTIVIST_DEMANDS_ALERT_OPTION_ID,
  ALERT_SUBOPTION_ACTIVIST_ACTION,
  ALERT_SUBOPTION_ACTIVISM_EVENTS,
  ALERT_SUBOPTION_FILING_TYPE,
  ALERT_SUBOPTION_SHORT_SELLER_ACTION,
  ALERT_SUBOPTION_SHORT_EVENTS,
  ALERT_SUBOPTION_GOV_POSITION,
  ALERT_SUBOPTION_GOV_APPOINT_CHANGE,
  ALERT_SUBOPTION_GOV_AMEND_CHANGE,
  ALERT_SUBOPTION_ACTIVISM_DEMAND_TYPE,
};
