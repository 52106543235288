export const INVESTOR_TRACKER_RESULT_DATA_LIMITED = 1;
export const INVESTOR_TRACKER_RESULT_FULL_DATA_LIMITED = 0;
export const INVESTOR_TRACKER_SHARES_OUTSTANDING = '% Shares Outstanding';
export const INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST = '% Shares FOR and AGAINST';
export const INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN = '% Shares FOR, AGAINST & ABSTAIN';
export const SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES = '% Shares FOR, AGAINST, ABSTAIN & Broker Non Votes';

export default {
  INVESTOR_TRACKER_RESULT_DATA_LIMITED,
  INVESTOR_TRACKER_RESULT_FULL_DATA_LIMITED,
  INVESTOR_TRACKER_SHARES_OUTSTANDING,
  INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST,
  INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN,
  SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES
};
