import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const getInvestorDDLList = async (res) => {
  try {
    const response = await api.post(config.getInvestorDDLList, {
      investor_id: res.investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getVotingProfileTopSection = async (res) => {
  try {
    const response = await api.post(config.getVotingProfileTopSection, {
      investor_id: res.investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getVotingProfileBottomSection = async (res) => {
  try {
    const response = await api.post(config.getVotingProfileBottomSection, {
      investor_id: res.investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getInvestorVotingProfileDummyData = async () => {
  try {
    const response = await api.post(config.investorVotingProfileDummyData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getVotingTabDataCheck = async (res) => {
  try {
    const response = await api.post(config.getVotingTabDataCheck, { investor_id: res.investor_id });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getCompensationonlyfilter = async (res) => {
  try {
    const response = await api.post(config.getCompensationonlyfilter, {
      investor_id: res.investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
