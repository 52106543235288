export const PRODUCT_TYPE_ISSUER_ACTIVISM = 'ActPid';
export const PRODUCT_TYPE_ISSUER_VOTING = 'VotPid';
export const PRODUCT_TYPE_ISSUER_ACTIVIST_VULNERABILITY = 'VulPid';
export const PRODUCT_TYPE_ISSUER_ACTIVIST_SHORTS = 'AShPid';
export const PRODUCT_TYPE_ISSUER_GOVERNANCE = 'GovPid';
export const PRODUCT_TYPE_ISSUER_PEOPLE = 'PplDir';

export const PRODUCT_TYPE_INVESTOR_ACTIVISM = 'ActInv';
export const PRODUCT_TYPE_INVESTOR_VOTING = 'VotInv';
export const PRODUCT_TYPE_INVESTOR_PRO_VOTING = 'VotPro ';
export const PRODUCT_TYPE_INVESTOR_ACTIVIST_VULNERABILITY = 'VulInv';
export const PRODUCT_TYPE_INVESTOR_ACTIVIST_SHORTS = 'AShInv';
export const PRODUCT_TYPE_INVESTOR_GOVERNANCE = 'GovInv';

export default {
  PRODUCT_TYPE_ISSUER_ACTIVISM,
  PRODUCT_TYPE_ISSUER_VOTING,
  PRODUCT_TYPE_ISSUER_ACTIVIST_VULNERABILITY,
  PRODUCT_TYPE_ISSUER_ACTIVIST_SHORTS,
  PRODUCT_TYPE_ISSUER_GOVERNANCE,
  PRODUCT_TYPE_INVESTOR_ACTIVISM,
  PRODUCT_TYPE_INVESTOR_VOTING,
  PRODUCT_TYPE_INVESTOR_PRO_VOTING,
  PRODUCT_TYPE_INVESTOR_ACTIVIST_VULNERABILITY,
  PRODUCT_TYPE_INVESTOR_ACTIVIST_SHORTS,
  PRODUCT_TYPE_INVESTOR_GOVERNANCE,
  PRODUCT_TYPE_ISSUER_PEOPLE
};
