import dateFormat from 'dateformat';
import {
  PublicCampaignToolLists as publicCampaignToolLists_config,
  getHoldingsDataAndAnalyticsData,
  getGlobalGovProvisionList as getGlobalGovProvisionList_config,
  getAllMeetingType,
  getCountryGovList as getCountryGovList_config,
  getAllGroupProponent,
  getAllIndividualProponent,
  getStateGovList as getStateGovList_config,
  resolutionsByInvestorFilter,
  getStateGovDetailList as getStateGovDetailList_config,
  getResolutionsByTarget,
  GetGlobalGovToolTrialList as GetGlobalGovToolTrialList_config,
  resolutionSearchByInvestor,
  GetPerformanceOverviewV2 as GetPerformanceOverviewV2_config,
  getHistoricalTrends,
  GetPerformanceCompounded as GetPerformanceCompounded_config,
  getInvestorVotingPower,
  ListFundPerformanceByYearV2 as ListFundPerformanceByYearV2_config,
  getVulDDL as getVulDDL_config,
  getVCId as getVCId_config,
  GetVulDataList as GetVulDataList_config,
  getResolutionsTypeIdByName,
  getVotingToolNoActionLetterDDL as getVotingToolNoActionLetterDDL_config,
  getVotingToolNoActionLetterAnalysisData as getVotingToolNoActionLetterAnalysisData_Config,
  noActionLetterDummyData,
  getActivistCampaignsTool,
  getLawFirmsDataActivismAdvisor,
  getDirectorSectorAndIndustrySearchData,
  getToolsActivismFillingsData,
  getLawFirmsDataShortActivismAdvisor,
  getPoisonPillStats,
  getRightsAgent,
  getPoisonPillRecentInsightia,
  getRegionsTrade,
  getInvestmentPublicData,
  getCompaniesTargetedTrends,
  getActiveActivistsTrends,
  getActiveCompanyRegiontrends,
  getActiveActivistsRegiontrends,
  getIndustryTargetedTrends,
  getCompaniesWithMultipleactivistsTrends,
  getMarketCapbyYearTrends,
  getActiveActivistsAUM,
  getSuccessRatesTrends,
  getStoredProcedureDownloadExcel,
  getAIGUpcomingMeetings,
  getUpcomingAppointmentsAndDepartures,
  investorComparatorhistoricalTrendsChartYTDData,
  investorComparatorhistoricalTrendsChartProxySeasonData,
  getUpcomingProposalDeadlineDates,
  getHistoricalTrendsChartDataInvestorComparator,
  getSettlementAgreementTableData,
  getProxyContestVotingSummaryData,
  getProxyContestVotingSummaryAnalysis,
  getProxyWinnerData,
  getActivistVulnerabilityResults,
  getActivistVulnerabilityResultsColumns,
} from '../config/server-config';
import { dateToISOString } from './general-util';
import { API_CALL_SUCCESSFULL, NUMBER_ZERO } from '../constants/NumberConstants';
import { ACTIVISM, GOVERNANCE, VOTING, ACTIVIST_VULNERABILITY, ACTIVIST_SHORTS } from '../constants/ProductConstants';
import { TOOLMENU } from '../constants/PathsConstant';
import { history } from './navigation-util';
import { FULL_USER, TRIAL_USER } from '../constants/CompanyTrialTypeConstants';
import { FEATURE_ACCESS_ENABLED, FEATURE_ACCESS_LIMITED } from '../constants/FeatureAccessConstants';
import api from '../api';

// Public Demands Tool
export const PublicCampaignToolLists = async (status, product_id, cancelToken) => {
  try {
    const response = await api.post(
      publicCampaignToolLists_config,
      {
        status,
        product_id,
      },
      {
        cancelToken: cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// holdings data and analytics
export const HoldingsDataAndAnalyticsList = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      getHoldingsDataAndAnalyticsData,
      {
        product_id: ACTIVISM,
        token,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
// Global Governance Tool
export const getGlobalGovProvisionList = async () => {
  try {
    const response = await api.post(getGlobalGovProvisionList_config);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetAllMeetingTypes = async () => {
  try {
    const response = await api.post(getAllMeetingType);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getCountryGovList = async (country_id) => {
  try {
    const response = await api.post(getCountryGovList_config, {
      country_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetAllGroupProponent = async () => {
  try {
    const response = await api.post(getAllGroupProponent);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetAllIndividualProponent = async () => {
  try {
    const response = await api.post(getAllIndividualProponent);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getStateGovList = async (filterType) => {
  try {
    const response = await api.post(getStateGovList_config, {
      filterType,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const ResolutionsByInvestorFilter = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      resolutionsByInvestorFilter,
      {
        StartDate: req.startDate ? dateFormat(req.startDate, 'yyyy-mm-dd') : null, //new change
        EndDate: req.endDate ? dateFormat(req.endDate, 'yyyy-mm-dd') : null, //new change
        MeetingType: req.meetingType,
        Proponent: req.proponent,
        ProposalSponsor: req.proposalSponsor,
        CopampanySearchId: req.companySearchId,
        InvestorSearchId: req.investorSearchId,
        managementRecc: req.managementRecc,
        outcome: req.outcome,
        excludeNpxCompensation: req.excludeNpxCompensation,
        product_id: VOTING,
        token,
        investor_id: req.investor_id,
        cancelToken: req.cancelToken,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getStateGovDetailList = async (stateName) => {
  try {
    const response = await api.post(getStateGovDetailList_config, {
      stateName,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const ResolutionsByTarget = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getResolutionsByTarget, {
      StartDate: req.startDate ? dateFormat(req.startDate, 'yyyy-mm-dd') : null, //new change
      EndDate: req.endDate ? dateFormat(req.endDate, 'yyyy-mm-dd') : null, //new change
      MeetingType: req.meetingType,
      Proponent: req.proponent,
      ProposalSponsor: req.proposalSponsor,
      CopampanySearchId: req.companySearchId,
      InvestorSearchId: req.investorSearchId,
      ProposalTypeToplevel: req.proposalTypeTopLevel,
      ProposalTypeSublevel: req.ProposalTypeSubLevel,
      ProposalType: req.proposalType,
      product_id: VOTING,
      token,
      managementRecc: req.managementRecc,
      outcome: req.outcome,
      excludeNpxCompensation: req.excludeNpxCompensation,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetGlobalGovToolTrialList = async () => {
  try {
    const response = await api.post(GetGlobalGovToolTrialList_config);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const ResolutionSearchByInvestor = async (
  startDate,
  endDate,
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  managementRecc,
  outcome,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(resolutionSearchByInvestor, {
      StartDate: dateToISOString(startDate),
      EndDate: dateToISOString(endDate),
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      product_id: VOTING,
      token,
      managementRecc,
      outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetPerformanceOverviewV2 = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(GetPerformanceOverviewV2_config, {
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Investor comparator tool
export const GetHistoricalTrends = async (
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  defaultSelection,
  managementRecc,
  outcome,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getHistoricalTrends, {
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      DefaultSelection: defaultSelection,
      managementRecc,
      outcome,
      product_id: VOTING,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const InvestorComparatorhistoricalTrendsChartYTDData = async (
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  defaultSelection,
  managementRecc,
  outcome,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(investorComparatorhistoricalTrendsChartYTDData, {
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      DefaultSelection: defaultSelection,
      product_id: VOTING,
      token,
      managementRecc: managementRecc,
      outcome: outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const InvestorComparatorhistoricalTrendsChartProxySeasonData = async (
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  defaultSelection,
  managementRecc,
  outcome,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(investorComparatorhistoricalTrendsChartProxySeasonData, {
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      DefaultSelection: defaultSelection,
      product_id: VOTING,
      token,
      managementRecc: managementRecc,
      outcome: outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const GetHistoricalTrendsChartDataInvestorComparator = async (
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  defaultSelection,
  managementRecc,
  outcome,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getHistoricalTrendsChartDataInvestorComparator, {
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      DefaultSelection: defaultSelection,
      product_id: VOTING,
      token,
      managementRecc: managementRecc,
      outcome: outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
//

export const GetPerformanceCompounded = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(GetPerformanceCompounded_config, {
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetInvestorVotingPower = async (
  startDate,
  endDate,
  meetingType,
  proponent,
  proposalSponsor,
  companySearchId,
  investorSearchId,
  proposalTypeTopLevel,
  ProposalTypeSubLevel,
  proposalType,
  managementRecc,
  outcome,
  defaultSelection,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getInvestorVotingPower, {
      StartDate: dateToISOString(startDate),
      EndDate: dateToISOString(endDate),
      MeetingType: meetingType,
      Proponent: proponent,
      ProposalSponsor: proposalSponsor,
      CopampanySearchId: companySearchId,
      InvestorSearchId: investorSearchId,
      ProposalTypeToplevel: proposalTypeTopLevel,
      ProposalTypeSublevel: ProposalTypeSubLevel,
      ProposalType: proposalType,
      DefaultSelection: defaultSelection,
      product_id: VOTING,
      token,
      managementRecc: managementRecc,
      outcome: outcome,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const ListFundPerformanceByYearV2 = async (Year) => {
  try {
    const response = await api.post(ListFundPerformanceByYearV2_config, {
      Year,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Vul Comparetor Tool

export const getVulDDL = async () => {
  try {
    const response = await api.post(getVulDDL_config);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const getVCId = async (res) => {
  try {
    const response = await api.post(getVCId_config, {
      VCId: res.vcid_VulTool,
      txtMarketCapMinRange: res.txtMarketCapMinRange,
      txtMarketCapMaxRange: res.txtMarketCapMaxRange,
      companySearchOptionSelection: res.companySearchOptionSelection,
      companySelection: res.companySelection,
      //
      SetKeyFinancials: res.SetKeyFinancials,
      SetKeyRatios: res.SetKeyRatios,
      SetVulnerability: res.SetVulnerability,
      SetOwnership: res.SetOwnership,
      SetGovernance: res.SetGovernance,
      SetVoting: res.SetVoting,
      SetPeerGroupSelection: res.SetPeerGroupSelection,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetVulDataList = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(GetVulDataList_config, {
      VCId: req.vcid,
      peerGroupSelection: req.SetPeerGroupSelection,
      product_id: ACTIVIST_VULNERABILITY,
      token,
      companySearchOptionSelection: req.companySearchOptionSelection,
      companySelection: req.companySelection,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetResolutionsTypeIdByName = async (lavel, proposalType) => {
  try {
    const response = await api.post(getResolutionsTypeIdByName, {
      Lavel: lavel,
      ProposalType: proposalType,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Voting Tool : No action letters

export const getVotingToolNoActionLetterDDL = async () => {
  try {
    const response = await api.post(getVotingToolNoActionLetterDDL_config);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getVotingToolNoActionLetterAnalysisData = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getVotingToolNoActionLetterAnalysisData_Config, {
      proposal_type: res.proposal_type,
      proponent: res.proponent,
      start_date: res.start_date,
      end_date: res.end_date,
      product_id: VOTING,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getNoActionLetterDummyData = async () => {
  try {
    const response = await api.post(noActionLetterDummyData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetActivistCampaignsTool = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      getActivistCampaignsTool,
      {
        product_id: ACTIVISM,
        token,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetLawFirmsDataActivismAdvisor = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      getLawFirmsDataActivismAdvisor,
      {
        product_id: ACTIVISM,
        token,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetDirectorSectorAndIndustrySearchData = async () => {
  try {
    const response = await api.post(getDirectorSectorAndIndustrySearchData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetToolsActivismFillingsData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      getToolsActivismFillingsData,
      {
        product_id: ACTIVISM,
        token,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetLawFirmsDataShortActivismAdvisor = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      getLawFirmsDataShortActivismAdvisor,
      { product_id: ACTIVIST_SHORTS, token },
      {
        cancelToken: res.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetPoisonPillStats = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getPoisonPillStats, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetRightsAgent = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getRightsAgent, { excel_download_id: 1, product_id: GOVERNANCE, token });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetPoisonPillRecentInsightia = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getPoisonPillRecentInsightia, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data[0];
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetRegionsTrade = async () => {
  try {
    const response = await api.post(getRegionsTrade);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetInvestmentPublicData = async () => {
  try {
    const response = await api.post(getInvestmentPublicData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetCompaniesTargetedTrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getCompaniesTargetedTrends, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetActiveActivistsTrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getActiveActivistsTrends, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetActiveCompanyRegiontrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getActiveCompanyRegiontrends, {
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetActiveActivistsRegiontrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getActiveActivistsRegiontrends, {
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetIndustryTargetedTrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getIndustryTargetedTrends, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetCompaniesWithMultipleactivistsTrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getCompaniesWithMultipleactivistsTrends, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetMarketCapbyYearTrends = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getMarketCapbyYearTrends, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetActiveActivistsAUM = async (res) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getActiveActivistsAUM, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      product_id: ACTIVISM,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetSuccessRatesTrends = async (res) => {
  try {
    const response = await api.post(getSuccessRatesTrends, {
      region: res.region,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetStoredProcedureDownloadExcel = async (res) => {
  try {
    const response = await api.post(getStoredProcedureDownloadExcel, {
      region: res.region,
      shortlong: res.shortlong,
      public: res.public,
      parameters: res.parameters,
      StoredProcedure: res.download,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetAIGUpcomingMeetings = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getAIGUpcomingMeetings, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetUpcomingAppointmentsAndDepartures = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getUpcomingAppointmentsAndDepartures, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetUpcomingProposalDeadlineDates = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getUpcomingProposalDeadlineDates, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const GetPageAccess = async (membership, product_id) => {
  try {
    const curr_product = membership.filter((product) => product.product_id === product_id);
    if (curr_product[NUMBER_ZERO].status === FULL_USER || curr_product[NUMBER_ZERO].status === TRIAL_USER) {
      return true;
    }
    history.push(TOOLMENU);
  } catch (e) {
    return false;
  }
  return false;
};

export const GetFeatureAccess = async (feature, feature_id) => {
  try {
    const curr_feature = feature.filter((product) => product.feature_id === feature_id);
    if (
      curr_feature[NUMBER_ZERO].status === FEATURE_ACCESS_ENABLED ||
      curr_feature[NUMBER_ZERO].status === FEATURE_ACCESS_LIMITED
    ) {
      return true;
    }
    history.push(TOOLMENU);
  } catch (e) {
    return false;
  }
  return false;
};
export const GetSettlementAgreementTableData = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(getSettlementAgreementTableData, {
      product_id: GOVERNANCE,
      token,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};
export const GetProxyContestVotingSummaryData = async (arg) => {
  try {
    const response = await api.post(getProxyContestVotingSummaryData, {
      CompanySearchId: arg.companySearchId,
      InvestorSearchId: arg.investorSearchId,
      MeetingType: arg.meetingType,
      StartDate: arg.startDate,
      EndDate: arg.endDate,
      Proponent: arg.proponent,
      Settlements: arg.settlements,
      ISSCard: arg.issCard,
      GLCard: arg.glCard,
      DesiredOutcome: arg.desiredOutcome,
      proxyWinnerID: arg.proxyWinnerID ? arg.proxyWinnerID : null,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetProxyContestVotingSummaryAnalysis = async (arg) => {
  try {
    const response = await api.post(getProxyContestVotingSummaryAnalysis, {
      CompanySearchId: arg.companySearchId,
      InvestorSearchId: arg.investorSearchId,
      MeetingType: arg.meetingType,
      StartDate: arg.startDate,
      EndDate: arg.endDate,
      Proponent: arg.proponent,
      Settlements: arg.settlements,
      ISSCard: arg.issCard,
      GLCard: arg.glCard,
      DesiredOutcome: arg.desiredOutcome,
      proxyWinnerID: arg.proxyWinnerID ? arg.proxyWinnerID : null,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetProxyWinnerData = async () => {
  try {
    const response = await api.post(getProxyWinnerData, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getActivistVulnerability = async (offset, amount, query, columns) => {
  try {
    const response = await api.post(getActivistVulnerabilityResults, {
      amount,
      offset,
      query,
      columns,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getActivistVulnerabilityColumns = async () => {
  try {
    const response = await api.get(getActivistVulnerabilityResultsColumns);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
