import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

// Defaults Preferences
export const GetCompanyPeerGroup = async () => {
  try {
    const response = await api.post(config.GetCompanyPeerGroup);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetInvestorPeerGroup = async () => {
  try {
    const response = await api.post(config.GetInvestorPeerGroup);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetPeerGroupsData = async () => {
  try {
    const response = await api.post(config.GetPeerGroupsData);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const AddSelectionInvestorPeerGroup = async (inv_peergroup_id) => {
  try {
    const response = await api.post(config.AddSelectionInvestorPeerGroup, {
      inv_peergroup_id: inv_peergroup_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const AddSelectionCompanyPeerGroup = async (cmp_peergroup_id) => {
  try {
    const response = await api.post(config.AddSelectionCompanyPeerGroup, {
      cmp_peergroup_id: cmp_peergroup_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// User Preferences
export const ValidateOldPasswordHash = async (req) => {
  try {
    const response = await api.post(
      config.ValidateOldPasswordHash,
      {
        txt_oldpassword: req.txt_oldpassword,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const HandleChangePassword = async (password) => {
  try {
    const response = await api.post(config.HandleChangePassword, {
      password: password,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Email Preferences
export const SavePreferencesV3 = async (res) => {
  try {
    const myJson = {
      Email1MonthlyNewsLetter: false,
      Email2EmailUpdates: false,
      Email33rdParty: false,
      daily_activim_newsletter: false,
      NAEmailUpdates: false,
      AAEmailUpdates: false,
      ais_weekly_newsletter: false,
    };
    res.map((item) => {
      if (item.children !== undefined && item.children.length > 0) {
        item.children.map((itemChildren) => {
          if (itemChildren.id === 'NAEmailUpdates') {
            myJson[itemChildren.id] = itemChildren.checked;
            return myJson[itemChildren.id];
          }
          if (itemChildren.id === 'AAEmailUpdates') {
            myJson[itemChildren.id] = itemChildren.checked;
            return myJson[itemChildren.id];
          }
          return '';
        });
      }
      if (item.id === 'Email1MonthlyNewsLetter') {
        myJson[item.id] = item.checked;
        return myJson[item.id];
      }
      if (item.id === 'Email2EmailUpdates') {
        myJson[item.id] = item.checked;
        return myJson[item.id];
      }
      if (item.id === 'Email33rdParty') {
        myJson[item.id] = item.checked;
        return myJson[item.id];
      }
      if (item.id === 'daily_activim_newsletter') {
        myJson[item.id] = item.checked;
        return myJson[item.id];
      }
      if (item.id === 'ais_weekly_newsletter') {
        myJson[item.id] = item.checked;
        return myJson[item.id];
      }
      return '';
    });

    const response = await api.post(config.SavePreferencesV3, {
      Email1MonthlyNewsLetter: myJson.Email1MonthlyNewsLetter,
      Email2EmailUpdates: myJson.Email2EmailUpdates,
      Email33rdParty: myJson.Email33rdParty,
      NAEmailUpdates: myJson.NAEmailUpdates,
      AAEmailUpdates: myJson.AAEmailUpdates,
      daily_newsletter: myJson.daily_activim_newsletter,
      ais_weekly_newsletter: myJson.ais_weekly_newsletter,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetEmailPreferences = async () => {
  try {
    const response = await api.post(config.GetEmailPreferences);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
