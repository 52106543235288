import React, { useState } from 'react';
import qs from 'qs';
import { SlideDown } from 'react-slidedown';
import bn from '../../utils/bemnames';
import Checkbox from './CheckboxComponent';
import ErrorBoundary from './ErrorBoundary';
import { FIVE_SECOND_TIMEOUT } from '../../constants/NumberConstants';

const headerbem = bn.create('header');

function getChecked(item, array, e) {
  if (item.checkedFn) {
    return item.checkedFn(array, item, e);
  }

  if (!item.main) {
    const foundItem = array.find((x) => x.module === item.module && x.main);
    if (foundItem) {
      return foundItem.checked;
    }
  }

  return item.checked;
}

const PDFNavMenu = ({
  toggleStatus = 0,
  handlePDFListItems,
  pdfListItems,
  handlePDFMenuShow,
  pdfMenuShow,
  pdfDownloadLoader,
  handlePDFDownloadLoader,
  isInvestorComparator,
  pdfDropdownHeightClassname,
  pdfBtnClassnames,
}) => {
  // this value can be 'flat' or 'nested'
  const menuVersion = toggleStatus === 1 ? 'flat' : 'nested';

  if (menuVersion === 'flat') {
    return (
      <PDFNavMenuFlat
        handlePDFListItems={handlePDFListItems}
        pdfListItems={pdfListItems}
        handlePDFMenuShow={handlePDFMenuShow}
        pdfMenuShow={pdfMenuShow}
        pdfDownloadLoader={pdfDownloadLoader}
        handlePDFDownloadLoader={handlePDFDownloadLoader}
        isInvestorComparator={isInvestorComparator}
        pdfDropdownHeightClassname={pdfDropdownHeightClassname}
        pdfBtnClassnames={pdfBtnClassnames}
      />
    );
  }

  return (
    <PdfNavMenuNested
      handlePDFListItems={handlePDFListItems}
      pdfListItems={pdfListItems}
      handlePDFMenuShow={handlePDFMenuShow}
      pdfMenuShow={pdfMenuShow}
      pdfDownloadLoader={pdfDownloadLoader}
      handlePDFDownloadLoader={handlePDFDownloadLoader}
      isInvestorComparator={isInvestorComparator}
      pdfDropdownHeightClassname={pdfDropdownHeightClassname}
      pdfBtnClassnames={pdfBtnClassnames}
    />
  );
};

export default React.memo(PDFNavMenu);

const PdfNavMenuNested = ({
  handlePDFListItems,
  pdfListItems,
  handlePDFMenuShow,
  pdfMenuShow,
  pdfDownloadLoader,
  handlePDFDownloadLoader,
  isInvestorComparator,
  pdfDropdownHeightClassname,
  pdfBtnClassnames,
}) => {
  const handleClick = (e) => {
    let array = [];
    array = pdfListItems.map((item) =>
      item.id === e.target.id
        ? {
            ...item,
            checked: e.target.checked,
          }
        : item
    );
    handlePDFListItems(array);
  };
  return (
    <ErrorBoundary>
      <span
        id='navbarDropdown'
        className={pdfBtnClassnames ? `${pdfBtnClassnames}` : 'pdfCustomStyle'}
        aria-hidden='true'
        onClick={() => handlePDFMenuShow(!pdfMenuShow)}
      >
        <button
          type='button'
          className={isInvestorComparator ? 'btn btn-primary btn-sm PDFButton PdfBtnOnRTTool' : 'btn PDFButton'}
          onClick={() => handlePDFMenuShow(!pdfMenuShow)}
        >
          <span>PDF Download</span>
          {pdfDownloadLoader && (
            // Loader
            <i title='waiting for generate a PDF' className='busy' />
          )}
        </button>
        <div
          className={pdfMenuShow ? headerbem.b('dropdown-menu show') : headerbem.b('dropdown-menu')}
          aria-labelledby='navbarDropdown'
        >
          <SlideDown className='my-dropdown-slidedown'>
            <div
              className={headerbem.b('card')}
              aria-hidden='true'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={headerbem.b('card-header text-transform-none PDFButton')}>
                Please note: PDF downloads may take a few minutes to complete. You can continue browsing the site whilst
                the PDF is downloading. Completed PDFs will appear in the Recent Downloads section found under the
                Settings cog icon. You will also receive an on-screen notification when the PDF is ready to view.
              </div>
              <div className='card-body'>
                <ul
                  className={`list-group list-group-flush scrollbar m-0 bg-white ${
                    pdfDropdownHeightClassname !== undefined ? pdfDropdownHeightClassname : 'pdfMenuListHeight'
                  }`}
                >
                  <li style={{ listStyleType: 'none' }}>
                    <div className='list-group-innertitle'>
                      <ul className='innerlist-group'>
                        {pdfListItems &&
                          pdfListItems.map((element, index) => (
                            <div key={`div${index + 1}`}>
                              {element.parent && element.parent !== undefined && (
                                <div
                                  className='d-flex ms-3'
                                  key={`ms${index + 1}`}
                                >
                                  <div className='m-0 me-2'>
                                    <span className='font-12'>
                                      <b>
                                        <u>{element.name}</u>
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {element.parent === undefined && (
                                <div
                                  className='d-flex'
                                  key={`flex${index + 1}`}
                                >
                                  <div className='m-0 me-2'>
                                    <Checkbox
                                      id={element.id}
                                      disabled={element.disabled}
                                      checked={element.checked}
                                      checkBoxClassName='appearance-auto smallcheckbox form-check-input p-2 me-2'
                                      labelName={element.name}
                                      labelClassName='font-12 p-0 col-form-label text-primary font-weight-bold'
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        !element.disabled && handleClick(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              {pdfListItems.length > 0 && (
                <div className='card-footer text-muted'>
                  <button
                    className='btn btn-primary float-end'
                    type='button'
                    disabled={
                      pdfListItems.filter((x) => !x.parent && x.disabled === true).length ===
                        pdfListItems.filter((x) => !x.parent).length ||
                      pdfDownloadLoader ||
                      !pdfListItems.filter((x) => x.checked === true).length
                    }
                    onClick={async (e) => {
                      e.preventDefault();
                      handlePDFMenuShow(!pdfMenuShow);
                      handlePDFDownloadLoader(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </SlideDown>
        </div>
      </span>
    </ErrorBoundary>
  );
};

const PDFNavMenuFlat = ({
  handlePDFListItems,
  pdfListItems,
  handlePDFMenuShow,
  pdfMenuShow,
  pdfDownloadLoader,
  handlePDFDownloadLoader,
  isInvestorComparator,
  pdfDropdownHeightClassname,
  pdfBtnClassnames,
}) => {
  const handleClick = (e) => {
    let array = [];
    array = pdfListItems.map((item) => {
      item.onChange && item.onChange(pdfListItems, item, e);
      return item.id === e.target.id
        ? {
            ...item,
            checked: e.target.checked,
          }
        : item;
    });
    array = array.map((item) => ({
      ...item,
      checked: getChecked(item, array, e),
      disabled: item.disabledFn ? item.disabledFn(array, item, e) : item.disabled,
    }));
    handlePDFListItems(array);
  };
  return (
    <ErrorBoundary>
      <span
        id='navbarDropdown'
        className={pdfBtnClassnames ? `${pdfBtnClassnames}` : 'pdfCustomStyle'}
        aria-hidden='true'
        onClick={() => handlePDFMenuShow(!pdfMenuShow)}
      >
        <button
          type='button'
          className={isInvestorComparator ? 'btn btn-primary btn-sm PDFButton PdfBtnOnRTTool' : 'btn PDFButton'}
          onClick={() => handlePDFMenuShow(!pdfMenuShow)}
        >
          <span>PDF Download</span>
          {pdfDownloadLoader && (
            // Loader
            <i title='waiting for generate a PDF' className='busy' />
          )}
        </button>
        <div
          className={pdfMenuShow ? headerbem.b('dropdown-menu show') : headerbem.b('dropdown-menu')}
          aria-labelledby='navbarDropdown'
        >
          <SlideDown className='my-dropdown-slidedown'>
            <div
              className={headerbem.b('card')}
              aria-hidden='true'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={headerbem.b('card-header text-transform-none PDFButton')}>
                Please note: PDF downloads may take a few minutes to complete. You can continue browsing the site whilst
                the PDF is downloading. Completed PDFs will appear in the Recent Downloads section found under the
                Settings cog icon. You will also receive an on-screen notification when the PDF is ready to view.
              </div>
              <div className='card-body'>
                <ul
                  className={`list-group list-group-flush scrollbar m-0 bg-white ${
                    pdfDropdownHeightClassname !== undefined ? pdfDropdownHeightClassname : 'pdfMenuListHeight'
                  }`}
                >
                  <li style={{ listStyleType: 'none' }}>
                    <div className='list-group-innertitle'>
                      <ul className='innerlist-group'>
                        <Menu pdfListItems={mapPdfListItems(pdfListItems)} handleClick={handleClick} />
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              {pdfListItems.length > 0 && (
                <div className='card-footer text-muted'>
                  <button
                    className='btn btn-primary float-end'
                    type='button'
                    disabled={
                      pdfListItems.filter((x) => !x.parent && x.disabled === true).length ===
                        pdfListItems.filter((x) => !x.parent).length ||
                      pdfDownloadLoader ||
                      !pdfListItems.filter((x) => x.checked === true).length
                    }
                    onClick={async (e) => {
                      e.preventDefault();
                      handlePDFMenuShow(!pdfMenuShow);
                      handlePDFDownloadLoader(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </SlideDown>
        </div>
      </span>
    </ErrorBoundary>
  );
};

function Menu({ pdfListItems, handleClick }) {
  if (!pdfListItems) return null;

  return pdfListItems.map((element, index) => (
    <div key={`div${index + 1}`}>
      {element.parent && element.parent !== undefined && (
        <div className='d-flex ms-3' key={`ms${index + 1}`}>
          <div className='m-0 me-2'>
            <span className='font-12'>
              <b>
                <u>{element.name}</u>
              </b>
            </span>
          </div>
        </div>
      )}
      {element.parent === undefined && (
        <div className='d-flex' key={`flex${index + 1}`}>
          <div className='m-0 me-2' style={{ paddingLeft: element.ident * 20 }}>
            <Checkbox
              id={element.id}
              disabled={element.disabled || (element.disabledFn && element.disabledFn(pdfListItems, element))}
              checked={element.checked}
              checkBoxClassName='appearance-auto smallcheckbox form-check-input p-2 me-2'
              labelName={element.name}
              labelClassName='font-12 p-0 col-form-label text-primary font-weight-bold'
              onChange={(e) => {
                e.stopPropagation();
                !element.disabled && handleClick(e);
              }}
            />
          </div>
        </div>
      )}
    </div>
  ));
}

function mapPdfListItems(pdfListItems) {
  const result = [];
  const visitedModules = [];

  pdfListItems.forEach((item) => {
    if (item.parent) {
      result.push(item);
    } else if (!visitedModules.includes(item.module)) {
      visitedModules.push(item.module);
      result.push({ ...item, links: [item.to] });
    } else {
      const foundModule = result.find((r) => r.module === item.module);
      if (foundModule) {
        foundModule.links.push(item.to);
      }
    }
  });

  return result;
}
