export const INVESTOR_COMPARATOR_MORE_DETAILS = 'More details';
export const INVESTOR_COMPARATOR_TRENDS = 'Trends';
export const INVESTOR_COMPARATOR_VOTING_POWER = 'Voting Power';
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_FOR = 'FOR';
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_AGAINST = 'Against';
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_ABSTAIN = 'Abstain';
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_DEFAULT_SELECTION = 5;
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_MAX_SELECTION = 10;
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_DEFAULT_INVESTOR_SELECTION = [72, 187, 1930, 486, 452];
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_FOR = 1;
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_AGAINST = 2;
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_ABSTAIN = 4;
export const INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_SUM = [1, 2, 3, 4, 11];
export const INVESTOR_COMPARATOR_PROPOSAL_TYPE_TOP_LAVEL_ALL = 0;
export const INVESTOR_COMPARATOR_PROPOSAL_TYPE_TOP_LAVEL = 1;
export const INVESTOR_COMPARATOR_PROPOSAL_TYPE_SUB_LAVEL = 2;
export const INVESTOR_COMPARATOR_PROPOSAL_TYPE = 3;

export default {
  INVESTOR_COMPARATOR_PROPOSAL_TYPE_TOP_LAVEL_ALL,
  INVESTOR_COMPARATOR_PROPOSAL_TYPE_TOP_LAVEL,
  INVESTOR_COMPARATOR_PROPOSAL_TYPE_SUB_LAVEL,
  INVESTOR_COMPARATOR_PROPOSAL_TYPE,
  INVESTOR_COMPARATOR_MORE_DETAILS,
  INVESTOR_COMPARATOR_TRENDS,
  INVESTOR_COMPARATOR_VOTING_POWER,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_FOR,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_AGAINST,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_ABSTAIN,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_DEFAULT_SELECTION,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_MAX_SELECTION,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_DEFAULT_INVESTOR_SELECTION,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_FOR,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_AGAINST,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_ABSTAIN,
  INVESTOR_COMPARATOR_HISTORICAL_TRENDS_VOTECAST_SUM
};
