import { Method, AxiosError } from 'axios';
import api from '../api';

/**
 * Base query for RTK Toolkit with our own axios instance
 * @param {string} [{ baseUrl }={ baseUrl: '' }]
 */
const axiosBaseQuery =
  ({ baseUrl }) =>
  async ({ url, method = 'get', body, params }) => {
    if (!baseUrl) {
      throw new Error('BaseUrl cannot be empty!');
    }
    try {
      const result = await api({
        url: baseUrl + url,
        method,
        data: body,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;

      if (err && err.response && err.response.status) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }

      return err;
    }
  };

export default axiosBaseQuery;
