import React from 'react';
import jwt from 'jsonwebtoken';
import { Redirect } from 'react-router';
import config from '../config/server-config';
import productConstant from '../constants/ProductConstants';
import PathsConstant from '../constants/PathsConstant';
import { isIdNotNullOrUndefined } from './general-util';
import { API_CALL_SUCCESSFULL, NUMBER_TWO, NUMBER_FOUR } from '../constants/NumberConstants';
import api from '../api';

export const CheckIsValidNewsPageUrl = async (path) => {
  try {
    const token = localStorage.getItem('token');
    jwt.verify(token, config.tokenSecrectKey, (err) => {
      if (err) {
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');

        return <Redirect to={PathsConstant.CREDENTIAL_FORM} />;
      }
    });

    const dToken = jwt.decode(token);
    if (dToken !== null) {
      const membership = dToken.MemberShip;
      if (
        path === PathsConstant.NEWS_ACTIVISM ||
        path === PathsConstant.NEWS_ACTIVISM_LATEST ||
        path === PathsConstant.NEWS_ACTIVISM_COVID19 ||
        path === PathsConstant.NEWS_ACTIVISM_THIS_WEEK ||
        path === PathsConstant.NEWS_ACTIVISM_WEEKLY_WRAP ||
        path === PathsConstant.NEWS_ACTIVISM_IN_DEPTH_ARTICLES
      ) {
        if (
          membership.findIndex(
            (p) => p.product_id === productConstant.ACTIVISM && (p.status === NUMBER_TWO || p.status === NUMBER_FOUR),
          ) >= 0
        ) {
          return true;
        }
      }

      if (path === PathsConstant.NEWS_ACTIVIST_SHORT || path === PathsConstant.NEWS_ACTIVIST_SHORT_IN_DEPTH_ARTICLES) {
        if (
          membership.findIndex(
            (p) =>
              p.product_id === productConstant.ACTIVIST_SHORTS && (p.status === NUMBER_TWO || p.status === NUMBER_FOUR),
          ) >= 0
        ) {
          return true;
        }
      }

      if (
        path === PathsConstant.NEWS_ACTIVIST_VULNERABILITY ||
        path === PathsConstant.NEWS_ACTIVIST_VULNERABILITY_LATEST ||
        path === PathsConstant.NEWS_ACTIVIST_VULNERABILITY_REPORT
      ) {
        if (
          membership.findIndex(
            (p) =>
              p.product_id === productConstant.ACTIVIST_VULNERABILITY &&
              (p.status === NUMBER_TWO || p.status === NUMBER_FOUR),
          ) >= 0
        ) {
          return true;
        }
      }

      if (path === PathsConstant.NEWS_GOVERNANCE) {
        if (
          membership.findIndex(
            (p) => p.product_id === productConstant.GOVERNANCE && (p.status === NUMBER_TWO || p.status === NUMBER_FOUR),
          ) >= 0
        ) {
          return true;
        }
      }

      if (path === PathsConstant.NEWS_VOTING || path === PathsConstant.NEWS_VOTING_IN_DEPTH_ARTICLES) {
        if (
          membership.findIndex(
            (p) => p.product_id === productConstant.VOTING && (p.status === NUMBER_TWO || p.status === NUMBER_FOUR),
          ) >= 0
        ) {
          return true;
        }
      }
    }

    return false;
  } catch (e) {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');

    return <Redirect to={PathsConstant.CREDENTIAL_FORM} />;
  }
};

export const AddNewsVisitorLog = async (page, mode, newsid) => {
  try {
    const response = await api.post(config.addNewsVisitorLog, {
      page,
      mode,
      newsid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetAllMostReadNews = async () => {
  try {
    const response = await api.post(config.getMostReadNews);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetAllLatestReadNews = async () => {
  try {
    const response = await api.post(config.getLatestReadNews);

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetAllProductLatestNews = async (
  productid,
  companyid,
  investorid,
  isVulnerabilityReport,
  isVulnerabilityArchive = false,
) => {
  try {
    const response = await api.post(config.getAllProductLatestNews, {
      ProductId: productid,
      CompanySearchId: companyid,
      InvestorSearchId: investorid,
      isVulnerabilityReport,
      isVulnerabilityArchive: isVulnerabilityArchive,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetAllInsightiaNewsData = async (req) => {
  try {
    const response = await api.post(config.getAllInsightiaNewsData, {
      ProductId: req.productid,
      CompanySearchId: req.companyid,
      InvestorSearchId: req.investorid,
      isVulnerabilityReport: req.isVulnerabilityReport,
      isVulnerabilityArchive: req.isVulnerabilityArchive,
      tag: req.tag,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const ListnewswithTag = async (tag) => {
  try {
    const response = await api.post(config.listnewswithTag, {
      tag,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const ListForViewNewsTimelineTop5 = async (newsid) => {
  try {
    const response = await api.post(config.listForViewNewsTimelineTop5, {
      newsid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
};

export const DummyActivismTimeline = async () => {
  try {
    const response = await api.post(config.dummyActivismTimeline);

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
};

export const GetLatestNewsFiltered = async () => {
  try {
    const response = await api.post(config.getLatestNewsFiltered);

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
};

export const GetNewsMoreInformationLinks = async (newsid) => {
  try {
    const response = await api.post(config.getNewsMoreInformationLinks, {
      newsid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
};

export const GetNewsDetails = async (newsid) => {
  try {
    if (isIdNotNullOrUndefined(newsid)) {
      const response = await getRequestFunction();
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }

  function getRequestFunction() {
    return api.post(config.getNewsDetails, { newsid });
  }
};

export const GetNews = async (pid) => {
  try {
    if (isIdNotNullOrUndefined(pid)) {
      const response = await api.post(config.getNews, {
        pid,
      });
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const GetNewsWithLinks = async (pid) => {
  try {
    if (isIdNotNullOrUndefined(pid)) {
      const response = await api.post(config.getNewsWithLinks, {
        pid,
      });
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const GetInvestorNews = async (pid) => {
  try {
    if (isIdNotNullOrUndefined(pid)) {
      const response = await api.post(config.getInvestorNews, {
        pid,
      });
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const GetInvestorNewsWithLinks = async (pid) => {
  try {
    if (isIdNotNullOrUndefined(pid)) {
      const response = await api.post(config.getInvestorNewsWithLinks, {
        pid,
      });
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const GetCompanyNewsIds = async (pid) => {
  try {
    const response = await api.post(config.getCompanyNewsIds, {
      pid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return {};
  }
};

export const GetInvestorNewsIds = async (investorid) => {
  try {
    const response = await api.post(config.getInvestorNewsIds, {
      investorid,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return {};
  }
};

export const GetNewsEvents = async (activismCategoryId, longShort, isAdmin) => {
  try {
    const response = await api.post(config.getNewsEvents, {
      ActivismCategoryId: activismCategoryId,
      LongShort: longShort,
      admin: isAdmin,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetStakeholding = async (activismCategoryId, longShort, isAdmin) => {
  try {
    const response = await api.post(config.getStakeholding, {
      ActivismCategoryId: activismCategoryId,
      admin: isAdmin,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const GetActivistObjective = async (activismCategoryId) => {
  try {
    const response = await api.post(config.getActivistObjective, {
      activismCategoryId,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const NewsFilter = async (
  CompanySearchId,
  InvestorSearchId,
  ActivistObjectiveGroupId,
  ActivistObjectiveTypeId,
  Stakeholding,
  Event,
  Freesearch,
  periodStart,
  periodEnd,
  product_id,
) => {
  try {
    const response = await api.post(config.newsFilter, {
      CompanySearchId,
      InvestorSearchId,
      ActivistObjectiveGroupId,
      ActivistObjectiveTypeId,
      Stakeholding,
      Event,
      Freesearch,
      periodStart,
      periodEnd,
      product_id,
    });

    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const getNewsNextPrevious = async (newsid) => {
  try {
    if (isIdNotNullOrUndefined(newsid)) {
      const response = await api.post(config.getNewsNextPrevious, {
        newsid,
      });
      if (response.status === API_CALL_SUCCESSFULL) {
        return response.data[0];
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const getProductMemberships = async (newsid) => {
  try {
    const response = await api.post(config.getProductMemberships, {});
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
