import config from '../config/server-config';
import { ICON_IMAGE_PATH } from '../constants/PathsConstant';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';
import { Markup } from 'interweave';

export const getPowerSearch = async (body) => {
  try {
    const response = await api.post(config.getPowerSearch, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getPowerSearchFilter = async (body) => {
  try {
    const response = await api.post(config.getPowerSearchFilter, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const createPowerSearchFilter = async (body) => {
  try {
    const response = await api.post(config.createPowerSearchFilter, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const listPowerSearchFilter = async (body) => {
  try {
    const response = await api.post(config.listPowerSearchFilter, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updatePowerSearchFilter = async (body) => {
  try {
    const response = await api.post(config.updatePowerSearchFilter, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const deletePowerSearchFilter = async (body) => {
  try {
    const response = await api.post(config.deletePowerSearchFilter, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const createVulnerabilityAdvancedSearchFilter = async (body) => {
  try {
    const response = await api.post(config.createPowerSearchFilterVuln, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const listVulnerabilityAdvancedSearchFilter = async (body) => {
  try {
    const response = await api.post(config.listPowerSearchFilterVuln, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updateVulnerabilityAdvancedSearchFilter = async (body) => {
  try {
    const response = await api.post(config.updatePowerSearchFilterVuln, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const deleteVulnerabilityAdvancedSearchFilter = async (body) => {
  try {
    const response = await api.post(config.deletePowerSearchFilterVuln, body);
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Templated from demandsImageHandlerHTML
export const powersearchImageHandlerHTML = (key, flag = false) => {
  const newkey = key;
  let imageDescription = '';
  let imageLocation = '';
  if (newkey === '' || typeof newkey === 'undefined' || newkey === null) {
    const wrapper = document.createElement('div');
    wrapper.classList.add('text-center');
    const text = document.createElement('span');
    text.textContent = '-';
    wrapper.appendChild(text);
    return <Markup content={wrapper.outerHTML} />;
  }
  if (flag) {
    if (newkey.toString() === 'Healthy') {
      imageLocation = 'GreenFlag.png';
      imageDescription = 'Green Flag';
    } else if (newkey.toString() === 'Unhealthy') {
      imageLocation = 'redflag_large.png';
      imageDescription = 'Red Flag';
    }
  } else {
    if (newkey.toString() === '1') {
      imageLocation = 'GreenYes.png';
      imageDescription = 'Green Tick';
    } else if (newkey.toString() === '0') {
      imageLocation = 'RedCross.png';
      imageDescription = 'Red Cross';
    }
  }

  // use createElement and appendchild as its faster than innerhtml
  const wrapper = document.createElement('div');
  wrapper.classList.add('text-center');
  const img = document.createElement('img');
  img.classList.add('smallIcon');
  img.src = `${window.location.origin}${ICON_IMAGE_PATH}${imageLocation}`;
  img.alt = imageDescription;
  img.title = imageDescription;
  wrapper.appendChild(img);
  // when returning do not put in brackets (NOT JSX)
  return <Markup content={wrapper.outerHTML} />;
};
