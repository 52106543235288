import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const GetSummary_ESGPeerCard = async (obj) => {
  try {
    const response = await api.post(
      config.getSummaryESGPeerCard,
      { pid: obj.pid },
      {
        cancelToken: obj.cancelToken,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistCampaignESGdata = async (obj) => {
  try {
    const response = await api.post(
      config.getActivistCampaignESG_data,
      { pid: obj.pid },
      {
        cancelToken: obj.cancelToken,
     }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetESGBreackDowndata = async (req) => {
  try {
    const response = await api.post(
      config.GetESGBreackDownData,
      {
        pid: req.pid,
      },
      {
        cancelToken: req.cancelToken,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export default {
  GetSummary_ESGPeerCard,
  GetESGBreackDowndata,
  GetActivistCampaignESGdata,
};
