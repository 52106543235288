import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../utils/axios-base-query';

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v2' }),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: ({ limit, offset, userid, category }) => {
        let requestString = `/news/getNews?limit=${limit}&offset=${offset}&userid=${userid}`;

        if (category) {
          requestString = `${requestString}&category=${category}`;
        }

        return { url: requestString };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { category } = queryArgs;
        return `${endpointName}-${category ?? ''}`;
      },
      merge: (currentCache, newItems) => {
        currentCache.push(...newItems);
      },
    }),
    getNewsDetails: builder.query({
      query: ({ userid, newsid }) => ({ url: `/news/getNewsDetails?userid=${userid}&newsid=${newsid}` }),
    }),
    getMostReadNews: builder.query({
      query: ({ userid }) => ({ url: `/news/getMostReadNews?userid=${userid}` }),
    }),
  }),
});

export const newsApiOld = createApi({
  reducerPath: 'newsApiOld',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v1' }),
  endpoints: (builder) => ({
    getLatestNewsFiltered: builder.query({
      query: ({ params }) => ({
        url: '/news/GetLatestNewsFiltered',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const { useGetNewsQuery, useLazyGetNewsQuery, useGetNewsDetailsQuery, useGetMostReadNewsQuery } = newsApi;
export const { useGetLatestNewsFilteredQuery } = newsApiOld;
