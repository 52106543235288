import { useGetFetureTogglesQuery } from './utils-api';

const getFeatureToggleStatus = (data, toggleLabel) => data?.data?.find((item) => item.name === toggleLabel)?.status;

export function useFeatureToggle() {
  const { data, isLoading } = useGetFetureTogglesQuery({});

  const IS_PDF_V2 = isLoading ? null : getFeatureToggleStatus(data, 'IS_PDF_V2');
  const ADV_VULN_SEARCH_V2 = getFeatureToggleStatus(data, 'ADV_VULN_SEARCH_V2');
  const IS_LENS_FONT = getFeatureToggleStatus(data, 'IS_LENS_FONT');

  return { IS_PDF_V2, ADV_VULN_SEARCH_V2, IS_LENS_FONT, isLoadingFeatureToggles: isLoading };
}
