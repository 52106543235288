// import ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
// import TrendsConst from '../../../constants/ActivismTrendsConstant';
import { GetSayOnPayData, GetSayOnPayAnalytics } from '../../../utils/toolsCompesation-utils';
// import { dateToNull, GetDefaultStartAndEndDate } from '../../../utils/general-util';
import resolutionTrackerToolConst from '../../../constants/ResolutionTrackerToolConstant';
import InvestorTrackerConstant from '../../../constants/InvestorTrackerConstant';

export const getSayOnPayDataReq = createAsyncThunk('getSayOnPayDataReq', async (req) => {
  const response = await GetSayOnPayData(req);
  return response;
});
export const getSayOnPayAnalyticsReq = createAsyncThunk('getSayOnPayAnalyticsReq', async (req) => {
  const response = await GetSayOnPayAnalytics(req);
  return response;
});

const sayOnPayVoteSlice = createSlice({
  name: 'sayOnPayVoteTool',
  initialState: {
    isAnalysis: false,
    lstSayOnPayData: [],
    lstSayOnPayAnalitycs: [],
    isLoadingSayOnPay: true,
    isLoadingSayOnPayA: true,

    SayOnPayVotesAnalysis: [],
    lstSayOnPayTotalVotesAnalysis: [],
    lstSayOnPayTotalVotesAnalysisSummary: [],
    lstSayOnPayTotalVotesAnalysisDetails: [],
    lstSayOnPayChartData: [],
    lstSayOnPayAnalysisChartData: [],
    SayOnPayVotesAnalysisYTD: [],
    lstSayOnPayAnalysisChartDataYTD: [],
    SayOnPayVotesProxySeasonAnalysis: [],
    lstSayOnPayAnalysisChartProxySeasonData: [],

    isSelectedFullYearData: true,
    isSelectedYearToDateData: false,
    isSelectedProxySeasonData: false,
    sayOnPayChartLevel: null,
    sayOnPayDdlData: null,

    ddlCalculationMethod: [
      {
        label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
        value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
      },
      {
        label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST,
        value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST,
      },
      {
        label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN,
        value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN,
      },
      {
        label: InvestorTrackerConstant.SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES,
        value: InvestorTrackerConstant.SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES,
      },
    ],
    ddlCalculationMethodSelection: {
      label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
      value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
    },
  },
  reducers: {
    handleSwitchDataAndStats: {
      reducer(state, action) {
        state.isAnalysis = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },

    handleOnChangedCalculationMethod: {
      reducer(state, action) {
        state.sayOnPayDdlData = action.payload.e;
        const voteAnalysis = current(state).SayOnPayVotesAnalysis;
        const voteAnalysisYTD = current(state).SayOnPayVotesAnalysisYTD;
        const voteAnalysisProxySeason = current(state).SayOnPayVotesProxySeasonAnalysis;

        if (action.payload.e === null) {
          state.ddlCalculationMethodSelection = {
            label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
            value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
          };
          if (current(state).isSelectedFullYearData) {
            state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysis.Sharesoutstanding;
          }
          if (current(state).isSelectedProxySeasonData) {
            state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisProxySeason.Sharesoutstanding;
          }
          if (current(state).isSelectedYearToDateData) {
            state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisYTD.Sharesoutstanding;
          }
        } else {
          state.ddlCalculationMethodSelection = action.payload.e;
          const labelSelected = action.payload.e !== undefined ? action.payload.e.label : '';
          if (current(state).isSelectedFullYearData) {
            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysis.Sharesoutstanding;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysis.ForAndAgainst;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysis.ForAgainstAndAbstain;
            }

            if (labelSelected === InvestorTrackerConstant.SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysis.ForAgainstAbstainandBrokerNonVotes;
            }
          }
          if (current(state).isSelectedProxySeasonData) {
            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisProxySeason.Sharesoutstanding;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisProxySeason.ForAndAgainst;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisProxySeason.ForAgainstAndAbstain;
            }

            if (labelSelected === InvestorTrackerConstant.SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisProxySeason.ForAgainstAbstainandBrokerNonVotes;
            }
          }
          if (current(state).isSelectedYearToDateData) {
            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisYTD.Sharesoutstanding;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AND_AGAINST) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisYTD.ForAndAgainst;
            }

            if (labelSelected === InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_FOR_AGAINST_ABSTAIN) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisYTD.ForAgainstAndAbstain;
            }

            if (labelSelected === InvestorTrackerConstant.SHARES_FOR_AGAINST_ABSTAIN_BROKER_NON_VOTES) {
              state.lstSayOnPayTotalVotesAnalysisDetails = voteAnalysisYTD.ForAgainstAbstainandBrokerNonVotes;
            }
          }
        }
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },

    handleOnClickIsSelectedFullYearData: {
      reducer(state, action) {
        let tableData = [];
        let chartData = [];
        const status = action.payload.e;
        state.sayOnPayChartLevel = action.payload.e;
        if (status === resolutionTrackerToolConst.USE_CALENDAR_YEAR_DATA) {
          state.isSelectedFullYearData = true;
          state.isSelectedYearToDateData = false;
          state.isSelectedProxySeasonData = false;
        }
        if (status === resolutionTrackerToolConst.USE_YEAR_TO_DATE_DATA) {
          state.isSelectedFullYearData = false;
          state.isSelectedYearToDateData = true;
          state.isSelectedProxySeasonData = false;
        }
        if (status === resolutionTrackerToolConst.USE_PROXY_SEASON_DATA) {
          state.isSelectedFullYearData = false;
          state.isSelectedYearToDateData = false;
          state.isSelectedProxySeasonData = true;
        }

        if (current(state).isSelectedFullYearData) {
          chartData = current(state).lstSayOnPayAnalysisChartData;
          tableData = current(state).SayOnPayVotesAnalysis;
        }
        if (current(state).isSelectedYearToDateData) {
          chartData = current(state).lstSayOnPayAnalysisChartDataYTD;
          tableData = current(state).SayOnPayVotesAnalysisYTD;
        }
        if (current(state).isSelectedProxySeasonData) {
          chartData = current(state).lstSayOnPayAnalysisChartProxySeasonData;
          tableData = current(state).SayOnPayVotesProxySeasonAnalysis;
        }
        state.lstSayOnPayChartData = chartData;

        state.lstSayOnPayTotalVotesAnalysis = tableData;
        state.lstSayOnPayTotalVotesAnalysisSummary = tableData.Summary;
        state.lstSayOnPayTotalVotesAnalysisDetails = tableData.Sharesoutstanding;

        // state.ddlCalculationMethodSelection = {
        //   label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
        //   value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
        // };
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },

    handleResetAllSayOnPay: {
      reducer(state, action) {
        state.isAnalysis = false;
        state.lstSayOnPayData = [];
        state.lstSayOnPayAnalitycs = [];
        state.isLoadingSayOnPay = true;
        state.isLoadingSayOnPayA = true;

        state.SayOnPayVotesAnalysis = [];
        state.lstSayOnPayTotalVotesAnalysis = [];
        state.lstSayOnPayTotalVotesAnalysisSummary = [];
        state.lstSayOnPayTotalVotesAnalysisDetails = [];
        state.lstSayOnPayChartData = [];
        state.lstSayOnPayAnalysisChartData = [];
        state.SayOnPayVotesAnalysisYTD = [];
        state.lstSayOnPayAnalysisChartDataYTD = [];
        state.SayOnPayVotesProxySeasonAnalysis = [];
        state.lstSayOnPayAnalysisChartProxySeasonData = [];

        state.isSelectedFullYearData = true;
        state.isSelectedYearToDateData = false;
        state.isSelectedProxySeasonData = false;
        state.ddlCalculationMethodSelection = {
          label: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
          value: InvestorTrackerConstant.INVESTOR_TRACKER_SHARES_OUTSTANDING,
        };
        state.sayOnPayChartLevel = null;
        state.sayOnPayDdlData = null;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },

    handleComapnySearchSelectionInvComp: {
      reducer(state, action) {
        state.isLoadingSayOnPay = true;
        state.invCompCompanyPeerGroupSelection = undefined;
        const data = JSON.parse(localStorage.getItem('companyFilterData'));
        state.invCompCompanyPeerGroupSelection =
          action.payload.e !== undefined ? action.payload.e : data;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleClearLoad: {
      reducer(state, action) {
        state.isLoadingSayOnPay = true;
        state.invCompCompanyPeerGroupSelection = undefined;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
  },
  extraReducers: {
    [getSayOnPayDataReq.fulfilled]: (state, action) => {
      state.lstSayOnPayData = action.payload ? action.payload : [];
      state.isLoadingSayOnPay = false;
    },
    [getSayOnPayAnalyticsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        //Initialisation and Calander
        state.SayOnPayVotesAnalysis =
          action.payload.FinancialAnalysis !== undefined ? action.payload.FinancialAnalysis : [];

        state.lstSayOnPayTotalVotesAnalysis =
          action.payload.FinancialAnalysis !== undefined ? action.payload.FinancialAnalysis : [];
        state.lstSayOnPayTotalVotesAnalysisSummary =
          action.payload.FinancialAnalysis !== undefined && action.payload.FinancialAnalysis !== undefined
            ? action.payload.FinancialAnalysis.Summary
            : [];
        state.lstSayOnPayTotalVotesAnalysisDetails =
          action.payload.FinancialAnalysis !== undefined && action.payload.FinancialAnalysis !== undefined
            ? action.payload.FinancialAnalysis.Sharesoutstanding
            : [];

        state.lstSayOnPayChartData =
          action.payload.FinancialGraph !== undefined ? action.payload.FinancialGraph.data : [];
        state.lstSayOnPayAnalysisChartData =
          action.payload.FinancialGraph !== undefined ? action.payload.FinancialGraph.data : [];

        //YTD
        state.SayOnPayVotesAnalysisYTD = action.payload.YTDAnalysis !== undefined ? action.payload.YTDAnalysis : [];
        state.lstSayOnPayAnalysisChartDataYTD = action.payload.YTDGraph !== undefined ? action.payload.YTDGraph.data : [];
        //PRoxy
        state.SayOnPayVotesProxySeasonAnalysis =
          action.payload.ProxySeasonAnalysis !== undefined ? action.payload.ProxySeasonAnalysis : [];
        state.lstSayOnPayAnalysisChartProxySeasonData =
          action.payload.ProxySeasonGraph !== undefined ? action.payload.ProxySeasonGraph.data : [];
      }
      state.isLoadingSayOnPayA = false;
    },
  },
});

export const {
  handleSwitchDataAndStats,
  handleOnClickIsSelectedFullYearData,
  handleOnChangedCalculationMethod,
  handleResetAllSayOnPay,
  handleComapnySearchSelectionInvComp,
  handleClearLoad,
} = sayOnPayVoteSlice.actions;

export default sayOnPayVoteSlice.reducer;
