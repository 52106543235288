import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetAIGUpcomingMeetings,
  GetUpcomingAppointmentsAndDepartures,
  GetUpcomingProposalDeadlineDates,
} from '../../../utils/tools-util';

export const getAIGUpcomingMeetingsReq = createAsyncThunk('getAIGUpcomingMeetingsReq', async () => {
  const response = await GetAIGUpcomingMeetings();
  return response;
});

export const getUpcomingAppointmentsAndDeparturesReq = createAsyncThunk(
  'getUpcomingAppointmentsAndDeparturesReq',
  async () => {
    const response = await GetUpcomingAppointmentsAndDepartures();
    return response;
  }
);

export const getUpcomingProposalDeadlineDatesReq = createAsyncThunk('getUpcomingProposalDeadlineDatesReq', async () => {
  const response = await GetUpcomingProposalDeadlineDates();
  return response;
});

const UpcomingEventsToolSlice = createSlice({
  name: 'upcomingMeetings',
  initialState: {
    defaultToggleData: '0', //"Option_meeting", "Option_appointments", "Option_proposal"
    isLoadingMeetingData: true,
    meetingCardData: [],
    appointmentsAndDeparturesCardData: [],
    proposalDeadlineCardData: [],
  },
  reducers: {
    handleSwitchDataAndStats: {
      reducer(state, action) {
        state.defaultToggleData = action.payload;
        state.isLoadingMeetingData = false;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleIsLoading: {
      reducer(state) {
        state.isLoadingMeetingData = true;
      },
      prepare() {
        return {};
      },
    },
  },
  extraReducers: {
    [getAIGUpcomingMeetingsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.meetingCardData = action.payload !== undefined ? action.payload : [];
      }
      state.isLoadingMeetingData = action.payload === undefined;
    },

    [getUpcomingAppointmentsAndDeparturesReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.appointmentsAndDeparturesCardData = action.payload !== undefined ? action.payload : [];
      }
    },

    [getUpcomingProposalDeadlineDatesReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.proposalDeadlineCardData = action.payload !== undefined ? action.payload : [];
      }
    },
  },
});

export const { handleSwitchDataAndStats, handleIsLoading } = UpcomingEventsToolSlice.actions;

export default UpcomingEventsToolSlice.reducer;
