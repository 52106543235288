import {
  //Ovesrview
  getActiviststrategyData,
  getHoldingsbyCountryChartData,
  getHoldingsbyIndustryChartData,
  getHoldingsbyExitTypeChartData,
  getHoldingsbyMarketCapChartData,
  getActivistProfileData,
  getCampaignTypesbyActivistLst,
  getActivistOfficesLst,
  getActivistPersonnelLst,
  getActivistTimelineLst,
  getActivistSharholderProposalsLst,
  //Investments
  getActivistHoldingsLst,
  get13F_Filings_by_ActivistLst,

  //Demands
  getActivistGBRCampaignsLst,
  getCampaignSummarybyActivistLst,

  //Follower returns
  getFollowerReturnsSearchLst,
  getFollowerReturnsActivistStatschartData,
  getFollowerReturnsActivistStatsData,

  //Performance
  getPerformancePeriodicbyActivistLst,
  getListofReprtingDate,
  getPerformanceAnnualbyActivistLst,
  getInvestorActivistCampaignsDataList,

  //Activism Access
  getInvestorActivisamTabDataCheck,
  //PDF
  getInvestorActivisamCampaignSummary,
  getActivistInvestmentListPDF,
  getActivismOverviewPDFData,
} from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

export const GetActiviststrategyData = async (actid) => {
  try {
    const response = await api.post(getActiviststrategyData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyCountryChartData = async (actid) => {
  try {
    const response = await api.post(getHoldingsbyCountryChartData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyIndustryChartData = async (actid) => {
  try {
    const response = await api.post(getHoldingsbyIndustryChartData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyExitTypeChartData = async (actid) => {
  try {
    const response = await api.post(getHoldingsbyExitTypeChartData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetHoldingsbyMarketCapChartData = async (actid) => {
  try {
    const response = await api.post(getHoldingsbyMarketCapChartData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistProfileData = async (actid) => {
  try {
    const response = await api.post(getActivistProfileData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCampaignTypesbyActivistLst = async (actid) => {
  try {
    const response = await api.post(getCampaignTypesbyActivistLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistOfficesLst = async (actid) => {
  try {
    const response = await api.post(getActivistOfficesLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistPersonnelLst = async (actid) => {
  try {
    const response = await api.post(getActivistPersonnelLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistTimelineLst = async (actid) => {
  try {
    const response = await api.post(getActivistTimelineLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistSharholderProposalsLst = async (actid) => {
  try {
    const response = await api.post(getActivistSharholderProposalsLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

//Investments

export const GetActivistHoldingsLst = async (actid) => {
  try {
    const response = await api.post(getActivistHoldingsLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Get13F_Filings_by_ActivistLst = async (actid) => {
  try {
    const response = await api.post(get13F_Filings_by_ActivistLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetActivistGBRCampaignsLst = async (actid) => {
  try {
    const response = await api.post(getActivistGBRCampaignsLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCampaignSummarybyActivistLst = async (actid) => {
  try {
    const response = await api.post(getCampaignSummarybyActivistLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetFollowerReturnsSearchLst = async (req) => {
  try {
    const response = await api.post(getFollowerReturnsSearchLst, {
      actid: req.actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetFollowerReturnsActivistStatschartData = async (actid) => {
  try {
    const response = await api.post(getFollowerReturnsActivistStatschartData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetFollowerReturnsActivistStatsData = async (actid) => {
  try {
    const response = await api.post(getFollowerReturnsActivistStatsData, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetPerformancePeriodicbyActivistLst = async (actid, dateReported) => {
  try {
    const response = await api.post(getPerformancePeriodicbyActivistLst, {
      actid,
      dateReported,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetListofReprtingDate = async (actid) => {
  try {
    const response = await api.post(getListofReprtingDate, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetPerformanceAnnualbyActivistLst = async (actid) => {
  try {
    const response = await api.post(getPerformanceAnnualbyActivistLst, {
      actid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvestorActivistCampaignsDataList = async (req) => {
  try {
    const response = await api.post(
      getInvestorActivistCampaignsDataList,
      {
        indiv_campaigns: req.indiv_campaigns,
        investor_id: req.investor_id,
        show_other_campaigns: req.show_other_campaigns,

        resultset: req.resultset !== undefined ? req.resultset : null,
      },
      {
        cancelToken: req.cancelToken,
      },
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvestorActivisamTabDataCheck = async (res) => {
  try {
    const response = await api.post(getInvestorActivisamTabDataCheck, {
      activist_id: res.activist_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
//PDF
// getInvestorActivisamCampaignSummary
export const GetInvestorActivisamCampaignSummary = async (req) => {
  try {
    const response = await api.post(getInvestorActivisamCampaignSummary, {
      activist_id: req.activist_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetActivistInvestmentListPDF = async (req) => {
  try {
    const response = await api.post(getActivistInvestmentListPDF, {
      activist_id: req.activist_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
// getActivismOverviewPDFData
export const GetActivismOverviewPDFData = async (req) => {
  try {
    const response = await api.post(getActivismOverviewPDFData, {
      activist_id: req.activist_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
