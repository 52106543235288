import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { dateToISOString } from './general-util';
import { ESG } from '../constants/ProductConstants';
import api from '../api';

export const GetESGScoreData = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      config.getESGScoreData,
      { company_search_id: req.company_search_id, product_id: ESG, token }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDirectorTypesData = async (req) => {
  // try {
  //   const response = await axios.post(
  //     config.GetDirectorTypes,
  //     {},
  //     {
  //       headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
  //     }
  //   );
  //   if (response.status === API_CALL_SUCCESSFULL) {
  //     return response.data;
  //   }
  // } catch (e) {
  //   return false;
  // }
};
