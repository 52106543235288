import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { Markup } from 'interweave';
import React from 'react';
import {
  GetCompensatioCompanyolicyDetailsHighestPaidExecutive,
  GetCompensationPolicyDetails,
  GetCompensationPolicyHighestPaidExecutiveData,
  GetCompensationReportYears,
} from '../../../../utils/company-util';

export const GetCompensatioCompanyolicyDetailsHighestPaidExecutiveReq = createAsyncThunk(
  'GetCompensatioCompanyolicyDetailsHighestPaidExecutiveReq',
  async (req) => {
    const response = await GetCompensatioCompanyolicyDetailsHighestPaidExecutive(req);
    return response;
  }
);

export const GetCompensationPolicyDetailsReq = createAsyncThunk('GetCompensationPolicyDetailsReq', async (req) => {
  const response = await GetCompensationPolicyDetails(req);
  return response;
});
export const GetCompensationPolicyHighestPaidExecutiveDataReq = createAsyncThunk(
  'GetCompensationPolicyHighestPaidExecutiveDataReq',
  async (req) => {
    const response = await GetCompensationPolicyHighestPaidExecutiveData(req);
    return response;
  }
);

export const GetCompensationReportYearsReq = createAsyncThunk('GetCompensationReportYearsReq', async (req) => {
  const response = await GetCompensationReportYears(req);
  return response;
});

const CompensationPolicyDetailsSlice = createSlice({
  name: 'companyCompensationPolicy',
  initialState: {
    isLoadingCompanyPolicy: true,
    tblShortTermIncentiveHighPaidExe: [],
    tblLongTermIncentiveHighPaidExe: [],
    tblCompensationPolicyDetailsOrg: [],
    tblCompensationPolicyDetails: undefined,
    ddlCompensationPolicyDetail: [],
    selectionCompensationPolicyDetasil: [],
    tblCompensationPolicyDetail: [],
    tblDynamicHeaders: [],
    isLoaded: false,
    compensationCurrencyData: [],
  },
  reducers: {
    handleResetLoading: {
      reducer(state, action) {
        state.isLoadingCompanyPolicy = true;
        state.tblShortTermIncentiveHighPaidExe = [];
        state.tblLongTermIncentiveHighPaidExe = [];
        state.tblCompensationPolicyDetailsOrg = [];
        state.tblCompensationPolicyDetails = undefined;
        state.ddlCompensationPolicyDetail = [];
        state.selectionCompensationPolicyDetasil = [];
        state.tblCompensationPolicyDetail = [];
        state.isLoaded = false;
        state.compensationCurrencyData = [];
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleSetIsLoaded: {
      reducer(state, action) {
        state.isLoaded = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnChangeDdlOption: {
      reducer(state, action) {
        state.selectionCompensationPolicyDetasil = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
  },
  extraReducers: {
    [GetCompensatioCompanyolicyDetailsHighestPaidExecutiveReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.tblShortTermIncentiveHighPaidExe =
          action.payload !== undefined ? action.payload.shortIncentiveExecutiveData : [];
        state.tblLongTermIncentiveHighPaidExe =
          action.payload !== undefined ? action.payload.longIncentiveExecutiveData : [];
        state.compensationCurrencyData = action.payload !== undefined ? action.payload.currencyData.CurrencyList : [];
      }
    },
    [GetCompensationPolicyDetailsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.tblCompensationPolicyDetailsOrg = action.payload !== undefined ? action.payload.data : [];
        state.tblCompensationPolicyDetails = action.payload.data !== undefined ? action.payload.data : [];
      }
    },
    [GetCompensationPolicyHighestPaidExecutiveDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        const compensationPolicyData = action.payload !== undefined ? action.payload.data : [];
        const dynamicHeader = action.payload !== undefined ? action.payload.header : [];
        const data1 = [];
        if (dynamicHeader.length > 0) {
          dynamicHeader.filter((item) => {
            const obj = {
              headerName: `${item}`,
              field: `${item}`,
              minWidth: 220,
              flex: 2,
              type: ['autoHeightTrue'],
              cellClass: current(state).TrialStatus
                ? 'ws-normal-lh24 ag-cell-blurrytext ps-1 pe-1'
                : 'ws-normal-lh24 ps-1 pe-1',
              cellRenderer: (params) => {
                const data = params.data[item] ? params.data[item] : '';
                const splitedData = data.split(',');
                const eDiv = document.createElement('div');
                splitedData.filter((item) => {
                  eDiv.innerHTML += `<span >
                ${item}
                </span><br />`;
                });
                return <Markup content={eDiv.outerHTML} />;
              },
            };
            data1.push(obj);
          });
        }
        state.tblDynamicHeaders = data1;
        let e = [];
        const modifiedprops = [];
        const modifiedprops1 = [];
        function compare(a, b) {
          if (Number(a.Rank) < Number(b.Rank)) {
            return -1;
          }
          if (Number(a.Rank) > Number(b.Rank)) {
            return 1;
          }
          return 0;
        }
        compensationPolicyData.forEach((e) => {
          if (e.data) {
            modifiedprops.push({
              Rank: e.Rank + 0.2,
              director_type: 'Compensation Structure',
              status: true,
              data: e.data,
              count: e.count,
            });
            modifiedprops1.push({
              Rank: e.Rank + 0.1,
              director_type: e.director_name,
              status: false,
              data: e.data,
              count: e.count,
            });
          } else {
            modifiedprops.push({
              Rank: e.Rank,
              status: false,
            });
          }
        });

        e = compensationPolicyData.concat(modifiedprops).concat(modifiedprops1).sort(compare);

        for (let i = 0; i < e.length; i += 1) {
          if (i % 2 !== 0) {
            e[i].Rank = '';
          }
        }
        state.tblCompensationPolicyDetail = e;
      }
    },
    [GetCompensationReportYearsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.ddlCompensationPolicyDetail = action.payload.yearData;
        if (action.payload.yearData && action.payload.yearData.length > 0) {
          state.selectionCompensationPolicyDetasil = action.payload.yearData[0];
        }
      }
    },
  },
});

export const { handleResetLoading, handleOnChangeDdlOption, handleSetIsLoaded } =
  CompensationPolicyDetailsSlice.actions;

export default CompensationPolicyDetailsSlice.reducer;
