import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  GetCompensationOverviewSummaryDetails,
  GetCompensationOverviewExecutiveAndDirectorDetails,
} from '../../../../utils/company-util';

export const getCompensationOverviewSummaryDetailsReq = createAsyncThunk(
  'getCompensationOverviewSummaryDetailsReq',
  async (req) => {
    const response = await GetCompensationOverviewSummaryDetails(req);
    return response;
  },
);
// GetCompensationOverviewExecutiveAndDirectorDetails
export const getCompensationOverviewExecutiveAndDirectorDetailsReq = createAsyncThunk(
  'getCompensationOverviewExecutiveAndDirectorDetailsReq',
  async (req) => {
    const response = await GetCompensationOverviewExecutiveAndDirectorDetails(req);
    return response;
  },
);

const CompanyCompensationOverviewSlice = createSlice({
  name: 'companyCompensationOverview',
  initialState: {
    preferencesIsLoading: true,
    compensationOverviewSummaryData: [],
    compensationOverviewExecutiveData: [],
    compensationOverviewExecutiveDataYearHeader: [],
    compensationOverviewDirectorData: [],
    isCompensationLoading: true,
    sparklineChartData: [],
    sparklineYearData: [],
    committeeHeaders: [],
    allnewcurrency: [],
    compensationOverviewPDFDirectorData: [],
  },
  reducers: {
    handleResetLoading: {
      reducer(state, action) {
        state.preferencesIsLoading = true;
        state.compensationOverviewSummaryData = [];
        state.compensationOverviewExecutiveData = [];
        state.compensationOverviewDirectorData = [];
        state.isCompensationLoading = true;
        state.sparklineChartData = [];
        state.sparklineYearData = [];
        state.committeeHeaders = [];
        state.allnewcurrency = [];
        state.compensationOverviewPDFDirectorData = [];
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    [getCompensationOverviewSummaryDetailsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.compensationOverviewSummaryData =
          action.payload.summaryData !== undefined ? action.payload.summaryData : [];
        state.sparklineChartData = action.payload !== undefined ? action.payload.chartData : [];
        state.sparklineYearData = action.payload !== undefined ? action.payload.yearData : [];
        const oldcurrency = current(state).allnewcurrency;
        const newcurrency = action.payload.currencyData;
        state.allnewcurrency = [...oldcurrency, newcurrency];
      }
      state.isCompensationLoading = action.payload === undefined;
    },
    [getCompensationOverviewExecutiveAndDirectorDetailsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.committeeHeaders = action.payload !== undefined ? action.payload.committeeHeaders : [];
        state.compensationOverviewExecutiveData = action.payload !== undefined ? action.payload.executieData : [];
        state.compensationOverviewExecutiveDataYearHeader =
          action.payload !== undefined ? action.payload.yearHeader : [];
        state.compensationOverviewDirectorData = action.payload !== undefined ? action.payload.directorData : [];
        const oldcurrency = current(state).allnewcurrency;
        const newcurrency = action.payload.currencyData;
        state.allnewcurrency = [...oldcurrency, newcurrency];
        state.compensationOverviewPDFDirectorData = action.payload ? action.payload.PDFDirectorSummary : [];
      }
    },
  },
});

export const { handleResetLoading } = CompanyCompensationOverviewSlice.actions;

export default CompanyCompensationOverviewSlice.reducer;
