import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

// import "core-js/features/string/repeat";
import assign from 'core-js/features/object/assign';
import 'core-js/features/string/virtual/replace-all';
//gridstack version@5.0.0
import 'gridstack/dist/gridstack-h5';
import 'jquery';
import 'gridstack/dist/gridstack.min.css';
//
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill/index';
import 'abortcontroller-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { newsApi, newsApiOld } from './features/v2/News/news-api';
import { votingApi } from './features/v2/Voting/voting-api';
import { utilsApi } from './features/v2/FeatureToggles/utils-api';

import './styles/main.scss';
import App from './App';
// import { fetchUsers } from './features/LoginContainer/loginSlice'
import rootReducer from './reducers';

Object.assign = assign;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(newsApi.middleware)
      .concat(newsApiOld.middleware)
      .concat(votingApi.middleware)
      .concat(utilsApi.middleware),
  ],
});

// eslint-disable-next-line import/prefer-default-export
export const persistor = persistStore(store);

// store.dispatch(fetchUsers())

const states = store.getState();
Cookies.set('headerTitleReducer', states.headerTitle, {
  secure: true,
  sameSite: 'strict',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
