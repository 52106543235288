import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import qs from 'qs';
import { history } from '../../../utils/navigation-util';
import pathConst, { QUERY_INVESTOR } from '../../../constants/PathsConstant';

const InvestorOwnershipHeader = (props) => {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { investor } = props;
  if (investor !== undefined) {
    query.investor = investor;
  }

  const navItems = [
    {
      to:
        props.location.pathname === pathConst.OWNERSHIP_INVESTOR_LONG_FUND
          ? pathConst.OWNERSHIP_INVESTOR_LONG_FUND
          : pathConst.OWNERSHIP_INVESTOR_LONG_INVESTOR,
      search: `${QUERY_INVESTOR}${query.investor}`,
      name: 'Long',
      exact: true,
      disabled: props.InvLongShort.long !== null && props.InvLongShort.long === 0
    },
    {
      to:
        props.location.pathname === pathConst.OWNERSHIP_INVESTOR_SHORT_FUND
          ? pathConst.OWNERSHIP_INVESTOR_SHORT_FUND
          : pathConst.OWNERSHIP_INVESTOR_SHORT_INVESTOR,
      search: `${QUERY_INVESTOR}${query.investor}`,
      name: 'Short',
      exact: false,
      disabled: props.InvLongShort.short !== null && props.InvLongShort.short === 0
    }
  ];

  return (
    <nav className='subnavbar navbar navbar-expand-md navbar-light bg-light'>
      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav me-auto'>
          {navItems.map(({ to, name, exact, search, disabled }, index) => (
            <li
              className='nav-item'
              key={`nav${index + 1}`}
              onMouseEnter={() => props.handleHoverSubmenu(to.substr(0, to.lastIndexOf('/')))}
              onMouseLeave={() => props.handleHoverSubmenu('')}
            >
              <NavLink
                to={to + search}
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: to,
                    search,
                    state: {}
                  });
                  props.handleResetBreadcrumbs(to);
                  props.handleResetCompanyPath(to);
                  props.handleVisitorLog(to, search);
                }}
                className={disabled ? 'nav-link disabled' : 'nav-link'}
                activeClassName={disabled ? '' : 'active'}
                id={`navItem-${name}-${index}`}
                exact={exact}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

InvestorOwnershipHeader.propTypes = {
  handleResetBreadcrumbs: PropTypes.func,
  handleResetCompanyPath: PropTypes.func,
  handleVisitorLog: PropTypes.func,
  investor: PropTypes.any,
  location: PropTypes.object,
  handleHoverSubmenu: PropTypes.func
};
InvestorOwnershipHeader.defaultProps = {
  handleHoverSubmenu: () => {},
  handleResetBreadcrumbs: () => {},
  handleResetCompanyPath: () => {},
  handleVisitorLog: () => {},
  location: {}
};
export default withRouter(InvestorOwnershipHeader);
