import React from 'react';

export const dataRelavanceTooltip =
  'Measures the total importance of all applicable metrics with raw data available. Depending on the configuration of the Scoring Profile, scores may be assigned for applicable metrics when raw data is not available. \n \n - A Data Relevance of 100 % means that all applicable metrics have raw data \n - A Data Relevance of 80 % means the total importance of metrics with raw data is 80 %, and the total importance of metrics with not available or potentially assigned scores is 20% \n - A Data Relevance of 0% means no applicable metric for the company has raw data, even though the company might have Total score due to the assigned scores';
export const peerPercentileTooltip =
  'The percentile rank of a company’s score relative to its peers.\n\nPeers refer to all companies within the same sub industry as the company in question, and with data relevance higher than the data relevance threshold of the profile. For example, a company with a score that is greater than or equal to 75% of all other companies within the same industry will be ranked it the 75th Peer Percentile.';

export const svgForNA = (
  <span title='This data is not available'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='13'
      fill='currentColor'
      className='bi bi-slash-circle'
      viewBox='0 0 16 16'
      xlinkTitle='This data is not available'
    >
      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
      <path
        fillRule='evenodd'
        d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'
      />
    </svg>
  </span>
);
export const signForNI = (
  <i
    className='bi bi-circle-fill'
    title='In calculating the total impact, the impact of this metric is negligible'
    style={{ color: '#A6A6A6' }}
  />
);
export const signForLI = (
  <i
    className='bi bi-exclamation-circle'
    style={{ color: '#EB8D53' }}
    title='There is not enough data to calculate this score'
  />
);
export const signForDash = (
  <span title='This data refers to pillars or metrics that do not apply to this companys, as they are only relevant to certain industries'>
    -
  </span>
);
export const signForXX = (
  <span title='Score assigned for Quantitative and/or Policy metrics based on users selection in Custom Scoring Profile'>
    XX*
  </span>
);
export const signForNU = (
  <i
    className='bi bi-x-circle'
    title='Organizations or all the organizaitons inside a fund are not included in the selected universe of comparison'
  />
);
export const signForRED = <i className='bi bi-circle-fill' style={{ color: '#C00000' }} />;
export const signForGreen = <i className='bi bi-circle-fill' style={{ color: '#548235' }} />;

const GapFunc = () => (
  <div
    className='d-inline-block'
    style={{
      width: '25px',
      margin: 0,
      padding: 0,
    }}
  />
);

export const RANGERED = (
  <div
    className='d-inline-block'
    style={{
      border: '1px solid #C00000',
      'border-radius': '20px 0 0 20px',
      background: '#C00000',
      width: '40px',
      height: '10px',
      margin: 0,
      padding: 0,
    }}
  />
);
export const RANGE_GAP_RED = GapFunc();

export const RANGEREDLIGHT = (
  <div
    className='d-inline-block'
    style={{
      border: '1px solid #FF3333',
      background: '#FF3333',
      width: '40px',
      height: '10px',
      margin: 0,
      padding: 0,
    }}
  />
);
export const RANGE_GAP_REDLIGHT = GapFunc();

export const RANGEYELLOW = (
  <div
    className='d-inline-block'
    style={{
      border: '1px solid #FFFF00',
      background: '#FFFF00',
      width: '80px',
      height: '10px',
      margin: 0,
      padding: 0,
    }}
  />
);
export const RANGE_GAP_YELLOW = (
  <div
    className='d-inline-block'
    style={{
      width: '60px',
      margin: 0,
      padding: 0,
    }}
  />
);

export const RANGEGREENLIGHT = (
  <div
    className='d-inline-block'
    style={{
      border: '1px solid #A9D08E',
      background: '#A9D08E',
      width: '40px',
      height: '10px',
      margin: 0,
      padding: 0,
    }}
  />
);
export const RANGE_GAP_GREENLIGHT = GapFunc();

export const RANGEGreen = (
  <div
    className='d-inline-block'
    style={{
      border: '1px solid #548235',
      'border-radius': '0 20px 20px 0',
      background: '#548235',
      width: '40px',
      height: '10px',
      margin: 0,
      padding: 0,
    }}
  />
);
export const RANGE_GAP_GREEN = GapFunc();

export const tooltipForESG = 'ESG Risk Score considering Environmental, Social and Governance factors.';
export const tooltipForQuantitative = 'Organization Quantitative score Average of E, S, and G quantitative scores for the organization, weighted based on the selected Scoring Profile. Considers each organization’s relative performance on a 1 (worst) to 100 (best) scale, based on the selected peer group.';
export const tooltipForPolicies = 'Company Policies score Average of E, S, and G policies scores of the company, weighted based on the selected profile Considers whether the policies are implemented (100) or not (1).';
export const tooltipForControversies = 'Company Controversies score Average of E, S, and G controversies scores for the company weighted based on the selected Scoring Profile. Considers the number and severity of controversies';

export const ESG_DARK_RED = '#C00000';
export const ESG_LIGHT_RED = '#FF3333';
export const ESG_ORANGE = '#FFFF00';
export const ESG_LIGHT_BLUE = '#A9D08E';
export const ESG_DARK_BLUE = '#548235';

export const ESG_INFO = 'ESG Risk Score considering Environmental, Social and Governance factors.';
export const QUANTITATIVE_INFO =
  'Organization Quantitative score Average of E, S, and G quantitative scores for the organization, weighted based on the selected Scoring Profile. Considers each organization’s relative performance on a 1 (worst) to 100 (best) scale, based on the selected peer group.';
export const POLICIES_INFO =
  'Company Policies score Average of E, S, and G policies scores of the company, weighted based on the selected profile Considers whether the policies are implemented (100) or not (1).';
export const CONTROVESIES_INFO =
  'Company Controversies score Average of E, S, and G controversies scores for the company weighted based on the selected Scoring Profile. Considers the number and severity of controversies';
export const INFO_ARR = [QUANTITATIVE_INFO, POLICIES_INFO, CONTROVESIES_INFO];
export const INFO_LOGO = [
  { key: 'chart', src: '/images/icons/pie-chart-icon.svg' },
  { key: 'text', src: '/images/icons/text-icon.svg' },
  { key: 'exclamation', src: '/images/icons/exclamation-icon.svg' }];
export const ESG_SUMMARY_NAME = ['Total', 'Environmental', 'Social', 'Governance'];

  export default {
    dataRelavanceTooltip,
    tooltipForControversies,
    tooltipForPolicies,
    tooltipForQuantitative,
    tooltipForESG,
    signForGreen,
    signForRED,
    signForNU,
    signForXX,
    signForDash,
    signForLI,
    signForNI,
    svgForNA,
    ESG_INFO,
    INFO_ARR,
    INFO_LOGO,
    ESG_SUMMARY_NAME,
    ESG_DARK_RED,
    ESG_LIGHT_RED,
    ESG_ORANGE,
    ESG_LIGHT_BLUE,
    ESG_DARK_BLUE,
    peerPercentileTooltip,
    RANGERED,
    RANGEREDLIGHT,
    RANGEYELLOW,
    RANGEGREENLIGHT,
    RANGEGreen,
    RANGE_GAP_RED,
    RANGE_GAP_REDLIGHT,
    RANGE_GAP_YELLOW,
    RANGE_GAP_GREENLIGHT,
    RANGE_GAP_GREEN,
};
