import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  GetCompensationComparatorData,
  GetDirectorTypesData,
  GetCompensationPolicySearchFilterData,
  GetCompensationPolicySearchData,
} from '../../../utils/toolsCompesation-utils';
import { GetDefaultStartAndEndDate } from '../../../utils/general-util';

const { startDate, endDate } = GetDefaultStartAndEndDate();

export const getCompensationComparatorDataReq = createAsyncThunk('getCompensationComparatorDataReq', async (req) => {
  const response = await GetCompensationComparatorData(req);
  return response;
});
export const GetDirectorTypesDataReq = createAsyncThunk('GetDirectorTypesDataReq', async (req) => {
  const response = await GetDirectorTypesData(req);
  return response;
});
// GetCompensationPolicySearchFilterData
export const GetCompensationPolicySearcFilterReq = createAsyncThunk(
  'GetCompensationPolicySearcFilterReq',
  async (req) => {
    const response = await GetCompensationPolicySearchFilterData(req);
    return response;
  }
);
//GetCompensationPolicySearchData
export const GetCompensationPolicySearchDataReq = createAsyncThunk(
  'GetCompensationPolicySearchDataReq',
  async (req) => {
    const response = await GetCompensationPolicySearchData(req);
    return response;
  }
);
const obj = [];
for (let i = 0; i <= 20; i++) {
  obj.push({
    label: i,
    value: i,
  });
}
const CompensationToolsSlice = createSlice({
  name: 'CompensationTools',
  initialState: {
    isComparatorToolLoading: true,
    tblCompensationComparatorToolData: undefined,
    selectionCompensationType: { label: 'Granted', value: 'Granted' },
    ddlCompensationType: [
      { label: 'Granted', value: 'Granted' },
      { label: 'Realised', value: 'Realised' },
      { label: 'Outstanding', value: 'Outstanding' },
    ],
    ddlCompensationTenure: obj,
    selectionDdlCompensationTenure: [],
    compensatinStartDate: startDate,
    compensationEndDate: endDate,
    isCompensationDateChecked: true,
    ddlDirectortypes: [],
    selectionDirectortypes: [],
    selectionCompensationIndivisual: [],
    //Compensation policy Tool
    ddlCompensationPolicyType: [],
    selectionCompensationPolicyType: {
      label: 'All',
      value: null,
    },
    ddlCompensationPolicyYear: [],
    selectionCompensationPolicyYear: {
      label: 'All',
      value: null,
    },
    txtCompensationFreeSearch: '',
    compensationPolicySearchToolData: undefined,
    isComparatorPolicyToolLoading: true,
    //
    tenureFrom: null,
    tenureTo: null,
  },
  reducers: {
    handleRegionChange: {
      reducer(state, action) {
        if (action.payload == null) {
          state.selectedRegion = state.defaultRegion;
        } else {
          state.selectedRegion = action.payload;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOnChangeDdlValue: {
      reducer(state, action) {
        state.selectionCompensationType = action.payload.e;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleResetComparatorToolData: {
      reducer(state, action) {
        state.isComparatorToolLoading = true;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleCompensationTenure: {
      reducer(state, action) {
        state.selectionDdlCompensationTenure = action.payload.e ? action.payload.e : undefined;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleCompensationDirectorType: {
      reducer(state, action) {
        state.selectionDirectortypes = action.payload.e ? action.payload.e : undefined;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleCompensationIndividualSelection: {
      reducer(state, action) {
        state.selectionCompensationIndivisual = action.payload.e ? action.payload.e : undefined;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleStartInvCompensationDateSelection: {
      reducer(state, action) {
        state.compensatinStartDate = action.payload.date;
        if (state.compensationEndDate <= action.payload.date) {
          state.compensationEndDate = action.payload.date;
        }
      },
      prepare(value, e) {
        return {
          payload: { date: new Date(value), e },
        };
      },
    },
    handleEndInvCompensationDateSelection: {
      reducer(state, action) {
        state.compensationEndDate = action.payload.date;
      },
      prepare(value, e) {
        return {
          payload: { date: new Date(value), e },
        };
      },
    },
    handleIsInvCompendationDateChecked: {
      reducer(state, action) {
        state.isCompensationDateChecked = action.payload.isChecked;
      },
      prepare(e) {
        return {
          payload: { isChecked: e.target.checked },
        };
      },
    },
    handleResetCompensationTools: {
      reducer(state, action) {
        state.selectionDdlCompensationTenure = [];
        state.selectionDirectortypes = [];
        state.selectionCompensationIndivisual = [];
        state.isComparatorToolLoading = true;
        state.tblCompensationComparatorToolData = undefined;
        state.compensatinStartDate = startDate;
        state.compensationEndDate = endDate;
      },
      prapare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnChangePolicyType: {
      reducer(state, action) {
        if (action.payload.e !== null) {
            const selectedData = action.payload.e;
            state.selectionCompensationPolicyType = selectedData.filter((item) => item.value !== null);
        } else {
          state.selectionCompensationPolicyType = [
            {
              label: 'All',
              value: null,
            },
          ];
        }
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnChangePolicyYear: {
      reducer(state, action) {
        if (action.payload.e !== null) {
            const selectedData = action.payload.e;
            state.selectionCompensationPolicyYear = selectedData.filter((item) => item.value !== null);
        } else {
          state.selectionCompensationPolicyYear = [
            {
              label: 'All',
              value: null,
            },
          ];
        }
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handledOnChangeFreeText: {
      reducer(state, action) {
        state.txtCompensationFreeSearch = action.payload.e.target.value;
      },
      prepare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnResetCompensationPolicySearchToolFilter: {
      reducer(state, action) {
        state.ddlCompensationPolicyType = [];
        state.selectionCompensationPolicyType = {
          label: 'All',
          value: null,
        };
        state.ddlCompensationPolicyYear = [];
        state.selectionCompensationPolicyYear = {
          label: 'All',
          value: null,
        };
        state.txtCompensationFreeSearch = '';
      },
      prapare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnResetCompensationPolicySearchToolData: {
      reducer(state, action) {
        state.compensationPolicySearchToolData = undefined;
        state.isComparatorPolicyToolLoading = true;
      },
      prapare(e) {
        return {
          payload: { e },
        };
      },
    },
    handleOnChangeTenureFrom: {
      reducer(state, action) {
        state.tenureFrom = action.payload !== '' ? action.payload : null;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeTenureTo: {
      reducer(state, action) {
        state.tenureTo = action.payload !== '' ? action.payload : null;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
  },
  extraReducers: {
    [getCompensationComparatorDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.tblCompensationComparatorToolData = action.payload ? action.payload : [];
        state.isComparatorToolLoading = action.payload === undefined;
      }
    },
    [GetDirectorTypesDataReq.fulfilled]: (state, action) => {
      state.ddlDirectortypes = action.payload ? action.payload : [];
    },
    [GetCompensationPolicySearcFilterReq.fulfilled]: (state, action) => {
      if (action.payload) {
        const policyType = action.payload.policyType;
        const policyYear = action.payload.policyYear;
        const oldData = current(state).ddlCompensationPolicyType;
        const oldYearData = current(state).ddlCompensationPolicyYear;
        state.ddlCompensationPolicyType = [...oldData, ...policyType];
        state.ddlCompensationPolicyYear = [...oldYearData, ...policyYear];
      }
    },
    [GetCompensationPolicySearchDataReq.fulfilled]: (state, action) => {
      state.compensationPolicySearchToolData = action.payload ? action.payload : [];
      state.isComparatorPolicyToolLoading = action.payload === undefined;
    },
  },
});

export const {
  handleRegionChange,
  handleOnChangeDdlValue,
  handleResetComparatorToolData,
  handleCompensationTenure,
  handleCompensationDirectorType,
  handleCompensationIndividualSelection,
  handleStartInvCompensationDateSelection,
  handleEndInvCompensationDateSelection,
  handleIsInvCompendationDateChecked,
  handleResetCompensationTools,
  //CompensationPolicySearch
  handleOnChangePolicyType,
  handleOnChangePolicyYear,
  handledOnChangeFreeText,
  handleOnResetCompensationPolicySearchToolFilter,
  handleOnResetCompensationPolicySearchToolData,
  //
  handleOnChangeTenureFrom,
  handleOnChangeTenureTo
} = CompensationToolsSlice.actions;

export default CompensationToolsSlice.reducer;
