import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { GetActivismNotifiedHoldingsToolList } from '../../utils/toolsNotifiedHoldings-utils';
import { TokenDecode } from '../../utils/general-util';

export const ActivismNotifiedHoldingsToolListReq = createAsyncThunk(
  'ActivismNotifiedHoldingsToolListReq',
  async (req) => {
    const response = await GetActivismNotifiedHoldingsToolList(req);
    const responseObj = {
      response,
    };
    if (!response) return false;
    return responseObj;
  }
);

export const getTokenDecode = createAsyncThunk(
  'getTokenDecodeHoldingsDataAndAnalytics',
  async () => {
    const response = await TokenDecode();
    return response;
  }
);

const NotifiedHoldingsToolSlice = createSlice({
  name: 'notifiedHoldingsTool',
  initialState: {
    isLoadingNotifiedHoldingsTool: true,
    notifiedHoldingsData: [],
    notifiedHoldingsDataHeading: {},
    getTokenDecode: [],
    allowDownload: false,
  },
  reducers: {
    handleResetNotifiedHoldingsTool: {
      reducer(state) {
        state.isLoadingNotifiedHoldingsTool = true;
        state.notifiedHoldingsData = [];
        state.notifiedHoldingsDataHeading = {};
        state.getTokenDecode = [];
        state.allowDownload = false;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    [ActivismNotifiedHoldingsToolListReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.notifiedHoldingsData =
          action.payload !== undefined ? action.payload.response.data : [];
        state.notifiedHoldingsDataHeading =
          action.payload !== undefined ? action.payload.response.dataHeading : {};
        state.isLoadingNotifiedHoldingsTool = false;
      }
    },
    [getTokenDecode.fulfilled]: (state, action) => {
      if (action.payload) {
        state.getTokenDecode = action.payload !== undefined ? action.payload : [];
      }
    },
  },
});

export const { handleResetNotifiedHoldingsTool } =
  NotifiedHoldingsToolSlice.actions;

export default NotifiedHoldingsToolSlice.reducer;
