import { createAction, createSlice } from '@reduxjs/toolkit';
import { loginFormReq, loginFormReqViaHighbond } from '../../LoginContainer/loginSlice';

export const oidcLoginAction = createAction('user/oidc-login');

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isSso: false,
  },
  reducers: {
    turnOnSso: (state) => {
      state.isSso = true;
    },
  },
  extraReducers: {
    [loginFormReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.userId = action.payload.userid;
      }
    },
    [loginFormReqViaHighbond.fulfilled]: (state, action) => {
      state.userId = action.payload.userid;
    },
    [oidcLoginAction.type]: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { turnOnSso } = userSlice.actions;
export default userSlice.reducer;
